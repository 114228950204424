<!-- start skill and about us section -->
<section
    id="sobre-nosotros"
    class="section-main secondary-bg-color section-bd-top about-with-skill-article"
>
    <div class="section-common-space">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-md-7">
                    <!-- start our-story -->
                    <article class="our-story">
                        <h2 class="section-title title-font">Sobre Nosotros</h2>
                        <p>
                            Somos una asesoría tecnológica especializada en la transformación digital de empresas, ayudándoles a mejorar su competitividad mediante soluciones IT personalizadas. Nuestra experiencia abarca la optimización de procesos y la gestión eficiente de recursos, enfocándonos en mejoras de productividad inmediatas. A través de tecnologías como la Inteligencia Artificial y las automatizaciones, impulsamos la colaboración y comunicación dentro de las organizaciones, eliminando tareas repetitivas y facilitando el trabajo en equipo.
                        </p>

                        <p>
                            Como Socios Oficiales de Google Cloud y expertos en Google Workspace, implementamos herramientas que ya utilizan millones de empresas a nivel mundial. Nuestro objetivo es automatizar procesos y ofrecer soluciones rápidas que simplifiquen las operaciones diarias, permitiendo a las empresas concentrarse en lo que realmente importa.
                        </p>
                        <div class="text-left block-btn-parent">
                            <a
                                href="#servicios"
                                itemprop="url"
                                data-target="#servicios"
                                class="menu-smooth-scroll mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-default btn-black"
                                >Servicios</a
                            >
                        </div>
                    </article>
                    <!-- end our-story -->
                </div>
                <div class="col-sm-6 col-md-5 mt-5">
                    <ul class="progress-bar-wrap" data-action="progress-bar">
                        <!-- start skill progress bar -->
                        <li class="narrow progress-bar-inner">
                            <!-- skill title -->
                            <span class="progress-bar-label"
                                >Google Workspace</span
                            >
                            <div class="progress-bar">
                                <!-- skill level-->
                                <div class="progress-bar-L1" data-width="100%">
                                    <div class="progress-conunt">
                                        <span>100%</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- end skill progress bar -->

                        <!-- start skill progress bar -->
                        <li class="narrow progress-bar-inner">
                            <!-- skill title -->
                            <span class="progress-bar-label"
                                >Transformación digital</span
                            >
                            <div class="progress-bar">
                                <!-- skill level-->
                                <div class="progress-bar-L1" data-width="95%">
                                    <div class="progress-conunt">
                                        <span>95%</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- end skill progress bar -->

                        <!-- start skill progress bar -->
                        <li class="narrow progress-bar-inner">
                            <!-- skill title -->
                            <span class="progress-bar-label">Automatización de procesos</span>
                            <div class="progress-bar">
                                <!-- skill level-->
                                <div class="progress-bar-L1" data-width="100%">
                                    <div class="progress-conunt">
                                        <span>100%</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- end skill progress bar -->

                        <!-- start skill progress bar -->
                        <li class="narrow progress-bar-inner">
                            <!-- skill title -->
                            <span class="progress-bar-label">Formación y Ponencias</span>
                            <div class="progress-bar">
                                <!-- skill level-->
                                <div class="progress-bar-L1" data-width="90%">
                                    <div class="progress-conunt">
                                        <span>90%</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- end skill progress bar -->
                         <!-- start skill progress bar -->
                        <li class="narrow progress-bar-inner">
                            <!-- skill title -->
                            <span class="progress-bar-label">Asesoría tecnológica</span>
                            <div class="progress-bar">
                                <!-- skill level-->
                                <div class="progress-bar-L1" data-width="100%">
                                    <div class="progress-conunt">
                                        <span>100%</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- end skill progress bar -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end skill and about us section -->
