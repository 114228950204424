<!-- start contact section -->
<section id="contacto" class="section-main contact devide-50 devide-bg">
    <div class="container">
        <div class="row">
            <!-- start contact left section/contact address -->
            <div class="col-sm-6 contact-info-start">
                <div class="section-common-space">
                    <h2 class="section-title title-font">CONTÁCTANOS</h2>
                    <div class="contact-info">
                        <div class="clearfix ci-items">
                            <span class="ci-icons"
                                ><i class="fas fa-home"></i
                            ></span>
                            <address>
                                <a
                                    href="https://goo.gl/maps/P3BD6d43SYk"
                                    itemprop="url"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Avda. de la Torrecilla, 16 1ª Planta,
                                    Oficina 105 14013, Córdoba</a
                                >
                            </address>
                        </div>
                        <div class="clearfix ci-items">
                            <span class="ci-icons"
                                ><i class="far fa-envelope"></i
                            ></span>
                            <address>info@enooby.com</address>
                        </div>
                        <div class="clearfix ci-items">
                            <span class="ci-icons"
                                ><i class="fas fa-phone"></i
                            ></span>
                            <address>957 11 99 11</address>
                        </div>
                    </div>
                </div>
            </div>
            <!--end left contact section -->

            <!-- start right contact section/contact form-->
            <div id="contacto" class="col-sm-6 contact-form-start">
                <div class="section-common-space">
                    <!-- contact.component.html -->
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="matx-form-valid contact-form">
                        <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="mdl-textfield mdl-js-textfield" [class.is-invalid]="contactForm.get('contactName')?.invalid && contactForm.get('contactName')?.touched">
                            <input
                                class="mdl-textfield__input"
                                type="text"
                                formControlName="contactName"
                                id="name"
                            />
                            <label class="mdl-textfield__label" for="name">Nombre</label>
                            <div class="invalid-feedback" *ngIf="contactForm.get('contactName')?.invalid && contactForm.get('contactName')?.touched">
                                El nombre es requerido y debe tener al menos 2 caracteres
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="mdl-textfield mdl-js-textfield" [class.is-invalid]="contactForm.get('contactEmail')?.invalid && contactForm.get('contactEmail')?.touched">
                            <input
                                class="mdl-textfield__input"
                                type="email"
                                formControlName="contactEmail"
                                id="email"
                            />
                            <label class="mdl-textfield__label" for="email">Email</label>
                            <div class="invalid-feedback" *ngIf="contactForm.get('contactEmail')?.invalid && contactForm.get('contactEmail')?.touched">
                                Por favor, introduce un email válido
                            </div>
                            </div>
                        </div>
                        </div>
                        
                        <div class="row">
                        <div class="col-sm-12">
                            <div class="mdl-textfield mdl-js-textfield">
                            <input
                                class="mdl-textfield__input"
                                type="text"
                                formControlName="contactSubject"
                                id="subject"
                            />
                            <label class="mdl-textfield__label" for="subject">Asunto</label>
                            </div>
                        </div>
                        </div>
                        
                        <div class="row">
                        <div class="col-sm-12">
                            <div class="mdl-textfield mdl-js-textfield mdl-textarea" [class.is-invalid]="contactForm.get('contactMessage')?.invalid && contactForm.get('contactMessage')?.touched">
                            <textarea
                                class="mdl-textfield__input"
                                formControlName="contactMessage"
                                id="message"
                            ></textarea>
                            <label class="mdl-textfield__label" for="message">Mensaje</label>
                            <div class="invalid-feedback" *ngIf="contactForm.get('contactMessage')?.invalid && contactForm.get('contactMessage')?.touched">
                                El mensaje es requerido y debe tener al menos 15 caracteres
                            </div>
                            </div>
                        </div>
                        </div>
                        
                        <div class="row">
                        <div class="col-sm-12">
                            <div class="text-left">
                            <button
                                type="submit"
                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-black btn-default btn-submit"
                                [disabled]="contactForm.invalid || loading"
                            >
                                {{ loading ? 'Enviando...' : 'Enviar' }}
                            </button>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- end right contact section -->
        </div>
    </div>
    <!-- start map section -->
    <div class="section bg-image-class location-on-map">
        <div class="overlay">
            <div class="text-center map-content">
                <div class="map-buttons">
                    <button
                        type="button"
                        id="map-open"
                        class="btn-active btn btn-block btn-tranparent btn-animated from-top zmdi zmdi-chevron-down"
                    >
                        <span>LOCALIZANOS EN EL MAPA</span>
                    </button>

                    <button
                        type="button"
                        id="map-close"
                        class="btn btn-block btn-tranparent btn-animated from-bottom zmdi zmdi-chevron-up"
                    >
                        <span>Cerrar el mapa</span>
                    </button>
                </div>
                <div class="map-wrapper">
                    <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3150.359503603332!2d-4.800772483852384!3d37.85187781549087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6cdf65fd6510bf%3A0xca322fd2ac61e835!2sEnooby!5e0!3m2!1ses!2ses!4v1643911141770!5m2!1ses!2ses" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end map section -->
</section>
<!-- end contact section -->
