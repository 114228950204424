<!-- start feature section with icon box -->
<section
    id="google-workspace"
    class="section-main section-bd-top about-featured-phone overflow-hidden"
>
    <div class="section-common-space">
        <div class="container">
            <div class="text-center section-heading">
                <h2 class="section-title">
                    <img
                        src="./assets/img/google-workspace/google-workspace-logo.png"
                        alt="Google Workspace"
                        width="500px"
                    />
                </h2>
                <h3 class="section-subtitle">
                    Correo electrónico y herramientas de colaboración de Google.
                </h3>
            </div>
            <div class="row">
                <div class="col-sm-4 col-md-4">
                    <!-- single icon box -->
                    <div
                        class="clearfix single-imodule csch-gray-white hov-white-red imodule-rightaligned imodule-round wow fadeInUp"
                        data-wow-duration="1s"
                    >
                        <div class="icon-box">
                            <img
                                src="./assets/img/google-workspace/gmail.png"
                                alt="gmail"
                                width="35"
                            />
                        </div>
                        <div class="ic-module-content">
                            <h4 class="imodule-title">Gmail</h4>
                            <p>
                                Correo electrónico con nuestro dominio, y
                                olvidarnos de virus, spam, limites de espacio...
                            </p>
                        </div>
                    </div>

                    <!-- single icon box -->
                    <div
                        class="clearfix single-imodule csch-gray-white hov-white-red imodule-rightaligned imodule-round wow fadeInUp"
                        data-wow-duration="1s"
                    >
                        <div class="icon-box">
                            <img
                                src="./assets/img/google-workspace/contacts.png"
                                alt="contactos"
                                width="35"
                            />
                        </div>
                        <div class="ic-module-content">
                            <h4 class="imodule-title">Contactos</h4>
                            <p>
                                Información siempre accesible y sincronizada
                                desde todos tus dispositivos.
                            </p>
                        </div>
                    </div>

                    <!-- single icon box -->
                    <div
                        class="clearfix single-imodule csch-gray-white hov-white-red imodule-rightaligned imodule-round wow fadeInUp"
                        data-wow-duration="1s"
                    >
                        <div class="icon-box">
                            <!--<i class="zmdi zmdi-google-play"></i>-->
                            <img
                                src="./assets/img/google-workspace/drive.png"
                                alt="drive"
                                width="35"
                            />
                        </div>
                        <div class="ic-module-content">
                            <h4 class="imodule-title">Drive</h4>
                            <p>
                                Almacenamiento online para guardar y compartir
                                tu trabajo de forma segura y accesible desde tu
                                portatil, tablet o teléfono.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 text-center module-center-image">
                    <img
                        src="assets/img/google-workspace/mobile.png"
                        alt="movil"
                    />
                </div>
                <div class="col-sm-4 col-md-4">
                    <!-- single icon box -->
                    <div
                        class="clearfix single-imodule csch-gray-white hov-white-red imodule-leftaligned imodule-round wow fadeInUp"
                        data-wow-duration="1s"
                    >
                        <div class="icon-box">
                            <img
                                src="./assets/img/google-workspace/calendar.png"
                                alt="calendar"
                                width="35"
                            />
                        </div>
                        <div class="ic-module-content">
                            <h4 class="imodule-title">Calendarios</h4>
                            <p>
                                Dedica menos tiempo a planificar y más a
                                trabajar, nuestra agenda disponible desde
                                cualquier dispositivo y lugar.
                            </p>
                        </div>
                    </div>

                    <!-- single icon box -->
                    <div
                        class="clearfix single-imodule csch-gray-white hov-white-red imodule-leftaligned imodule-round wow fadeInUp"
                        data-wow-duration="1s"
                    >
                        <div class="icon-box">
                            <!--<i class="zmdi zmdi-google-drive"></i>-->
                            <img
                                src="./assets/img/google-workspace/chat.png"
                                alt="chat"
                                width="35"
                            />
                        </div>
                        <div class="ic-module-content">
                            <h4 class="imodule-title">Chat</h4>
                            <p>
                                Conecta a través de videoconferencia, voz o
                                texto, ahorrando tiempo y dinero en
                                desplazamientos.
                            </p>
                        </div>
                    </div>

                    <!-- single icon box -->
                    <div
                        class="clearfix single-imodule csch-gray-white hov-white-red imodule-leftaligned imodule-round wow fadeInUp"
                        data-wow-duration="1s"
                    >
                        <div class="icon-box">
                            <img
                                src="./assets/img/google-workspace/sites.png"
                                alt="sitios"
                                width="35"
                            />
                        </div>
                        <div class="ic-module-content">
                            <h4 class="imodule-title">Sitios</h4>
                            <p>
                                Crea facilmente webs para tu empresa. Desde una
                                intranet hasta un sitio de proyectos para tu
                                equipo, y todo sin escribir ni una sola línea de
                                código.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-4 col-md-4"></div>
                <div class="col-sm-4 col-md-4 text-center module-center-image">
                    <a
                        href="https://workspace.google.es/intl/es/"
                        itemprop="url"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-lg btn-highlight btn-default tp-resizeme rs-parallaxlevel-0"
                        target="_blank"
                        rel="noreferrer"
                        >Saber más</a
                    >
                </div>
            </div>
        </div>
    </div>
</section>
