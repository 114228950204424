import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  contactForm: FormGroup;
  loading = false;
  debugInfo: any = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.contactForm = this.fb.group({
      contactName: ['', [Validators.required, Validators.minLength(2)]],
      contactEmail: ['', [Validators.required, Validators.email]],
      contactSubject: [''],
      contactMessage: ['', [Validators.required, Validators.minLength(15)]]
    });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.loading = true;
      this.debugInfo = null;

      const formData = new FormData();
      Object.keys(this.contactForm.value).forEach(key => {
        formData.append(key, this.contactForm.value[key]);
      });

      this.http.post('/assets/inc/sendEmail.php', formData)
        .pipe(
          catchError(this.handleError.bind(this)),
          finalize(() => this.loading = false)
        )
        .subscribe({
          next: (response: any) => {
            console.log('Respuesta completa del servidor:', response);
            this.debugInfo = response.debug;
            
            if (response.status === 'success') {
              this.showSuccess(response.message);
              this.contactForm.reset();
            } else {
              this.showError(response.message || 'Error desconocido');
            }
          }
        });
    }
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Error completo:', error);

    let errorMessage = 'Ha ocurrido un error desconocido';

    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Error del lado del servidor
      errorMessage = `Código de error: ${error.status}, ` +
                    `mensaje: ${error.message}`;
      
      // Intentar parsear el cuerpo de la respuesta si existe
      if (error.error) {
        try {
          const errorBody = error.error;
          console.log('Cuerpo del error:', errorBody);
          this.debugInfo = errorBody.debug;
          errorMessage = errorBody.message || errorMessage;
        } catch (e) {
          console.log('No se pudo parsear el cuerpo del error:', e);
        }
      }
    }

    this.showError(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  private showSuccess(message: string): void {
    alert(message || '¡Mensaje enviado correctamente!');
  }

  private showError(message: string): void {
    alert(message || 'Ha ocurrido un error al enviar el mensaje');
  }
}