<app-header-nav theme="bg-white"></app-header-nav>

<section class="privacy-policy">
    <div class="privacy-policy__title">
        <h1 class="text-white">Política de Privacidad</h1>
    </div>
</section>

<div class="container my-5">
    <p>
        ENOOBY SPAIN, S.L., con CIF. B-56.019.110 (en adelante, la “EMPRESA”),
        domicilio en Avda. de la Torrecilla, 16, 1ª Pta. Of. 105; D.P.: 14013 -
        Córdoba, teléfono.: 957119911, y email.: info@enooby.com es el titular
        del sitio web www.enooby.com (en adelante el “Sitio Web”) y el
        responsable del tratamiento de los datos personales que el usuario
        facilite al acceder o utilizar el sitio web y de todos los datos
        personales en general que se recogen en el mismo o gestiona la Empresa.
    </p>
    <p>
        La Empresa, informa, través de esta Política de Privacidad, sobre el
        tratamiento y protección de los datos de carácter personal de los
        usuarios que puedan ser recabados mediante su navegación o contratación
        de servicios que realice en este portal o por otras vías con la Empresa.
        El uso de esta web por parte del usuario conlleva la aceptación por su
        parte de esta Política de Privacidad.
    </p>

    <p>
        La Empresa adopta las medidas necesarias para garantizar la seguridad,
        integridad y confidencialidad de los datos conforme a lo establecido en
        el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
        de Abril de 2016, relativo a la protección de las personas físicas en lo
        que respecta al tratamiento de datos personales y a la libre circulación
        de los mismos, y en aquello no previsto por éste por la Ley Orgánica
        3/2018, de 5 de Diciembre, de Protección de Datos Personales y Garantía
        de los Derechos Digitales y la Ley 34/2002, de 11 de Julio, de Servicios
        de la Sociedad de la Información y de Comercio Electrónico.
    </p>

    <section class="mt-5">
        <h4>Finalidad</h4>
        <p>Los datos son recabados con las siguientes finalidades:</p>
        <ol class="ml-4" type="1">
            <li>
                Asegurar la correcta gestión de los servicios de consultoría en
                nuevas tecnologías:
                <ul class="ml-4">
                    <li>
                        Realización de presupuestos de los productos o servicios
                        ofertados por el responsable y su posterior seguimiento
                        comercial.
                    </li>
                    <li>
                        Contratación de nuestros servicios mediante la
                        aceptación del correspondiente presupuesto, pedido y/o
                        la firma de un contrato mercantil.
                    </li>
                    <li>
                        Prestación efectiva de los servicios y productos
                        contratados.
                    </li>
                    <li>
                        Proporcionarle información relacionada con los productos
                        y servicios que ofrece nuestra empresa y que se detallan
                        en este sitio web.
                    </li>
                    <li>
                        Realizar la facturación de los productos y/o servicios
                        contratados.
                    </li>
                    <li>
                        Garantizar la correcta gestión administrativa,
                        comercial, fiscal y contable de la empresa.
                    </li>
                    <p>
                        En el caso del uso de los datos con las finalidades
                        anteriormente descritas, los datos tratados serán datos
                        de identificación (nombre, apellidos, dirección, código
                        postal, localidad, provincia, NIF, email, teléfono,
                        móvil) y datos bancarios para la gestión de pago y
                        cobro.
                    </p>
                </ul>
            </li>
            <li>
                Remisión de información, atención de consultas y newsletter:
                <ul class="ml-4">
                    <li>
                        Gestionar las consultas, reclamaciones o solicitudes que
                        realice el usuario.
                    </li>
                    <li>
                        Mantenerle informado sobre nuestras actividades,
                        servicios, promociones, novedades, eventos y otras
                        noticias que puedan ser de su interés.
                    </li>
                    <p>
                        En este sentido los datos facilitados serán aquellos
                        necesarios para su identificación (nombre, apellidos,
                        email). El usuario puede oponerse al envío de
                        comunicaciones comerciales en cualquier momento mediante
                        el envío de un mensaje de correo electrónico a la
                        dirección indicada anteriormente.
                    </p>
                </ul>
            </li>
            <li>
                Selección de personal:
                <ul class="ml-4">
                    <li>
                        Realizar procesos de selección de personal para cubrir
                        los puestos de trabajos requeridos por el responsable.
                    </li>
                    <p>
                        Los datos recogidos para este tratamiento serán: datos
                        de identificación (nombre, apellidos, dirección, código
                        postal, localidad, provincia, NIF, correo electrónico,
                        teléfono móvil), datos de formación, datos de
                        experiencia profesional y cualquier otra información que
                        el solicitante crea adecuada para mejorar su candidatura
                        al puesto de trabajo.
                    </p>
                </ul>
            </li>
            <li>
                Sitio web:
                <ul class="ml-4">
                    <li>
                        Tratamiento de los datos obtenidos a través de Cookies
                        (véase la Política de Cookies).
                    </li>
                    <li>
                        Vigilancia del uso del sitio web, con el objetivo de
                        evitar y detectar actuaciones fraudulentas en ésta, así
                        como cualquier acceso no autorizado.
                    </li>
                </ul>
            </li>
            <li>
                Atención a los derechos de las personas:
                <ul class="ml-4">
                    <li>
                        Atender las solicitudes de los interesados en el
                        ejercicio de los derechos que establece el Reglamento
                        General de Protección de Datos.
                    </li>
                    <p>
                        La finalidad del tratamiento en ningún caso tiene por
                        objeto la elaboración de perfiles para la
                        personalización de publicidad propia o de terceros o el
                        análisis del tráfico en nuestra web.
                    </p>
                </ul>
            </li>
        </ol>
        <p>
            No tomamos decisiones automatizadas en base a perfiles, a excepción
            de la personalización de publicidad y la prevención del fraude en
            internet.
        </p>
        <p>
            Le informamos que podrá revocar el consentimiento, en cada
            comunicado comercial o publicitario que se le haga llegar, y en
            cualquier momento, mediante la notificación al domicilio de la
            Empresa, arriba reseñado, o a través de correo electrónico a
            info@enooby.com.
        </p>
    </section>

    <section class="mt-5">
        <h4>Base Jurídica y plazo de conservación:</h4>

        <p>
            El tratamiento de los datos personales que proporciona el usuario se
            realiza amparado en las siguientes bases jurídicas que legitiman el
            mismo:
        </p>
        <ul class="ml-4">
            <li>
                La contratación de servicios solicitados a la Empresa, cuyos
                términos y condiciones se pondrán a disposición del usuario de
                forma previa a una eventual contratación. Para poder llevar a
                cabo esta relación profesional solicitada, el interesado está
                obligado a facilitar sus datos. Los datos se conservarán
                mientras dure la relación contractual.
            </li>
            <li>
                El consentimiento libre, específico, informado e inequívoco,
                tanto en la remisión de un currículum, la cual es voluntaria, en
                el caso de remitirle desde la Empresa por email, sms o cualquier
                otro medio electrónico material de marketing (promociones,
                novedades, etc.), como en la inclusión de sus datos en algún
                apartado del presente sitio web, ya que tras la lectura de la
                presente Política de Privacidad el usuario queda informado y, en
                caso de estar conforme, puede aceptarla mediante una declaración
                o una clara acción afirmativa, como el marcado de una casilla
                que se dispone al efecto. En caso de que el interesado no
                facilite los datos o éstos sean erróneos o inexactos, no
                podremos atender su solicitud, resultando imposible
                proporcionarle la información solicitad. Como ya hemos informado
                anteriormente tendrá derecho a retirar su consentimiento en
                cualquier momento, sin que ello afecte a la licitud del
                tratamiento basado en el consentimiento previo a su retirada.
            </li>
            <li>
                Las obligaciones legales que en la Empresa debemos cumplir, para
                lo que es necesario el tratamiento de sus datos, por ejemplo la
                emisión de facturas o presentación de impuestos a la
                administración pública o para atender los derechos que nos
                solicite. Los datos se conservarán durante los plazos legalmente
                exigidos.
            </li>
            <li>
                El interés legítimo que nos asiste para el tratamiento de datos
                de contacto y, en su caso, los relativos a la función o puesto
                desempeñado, de las personas físicas que presten servicios en
                una persona jurídica, cuando el tratamiento se refiera
                únicamente a los datos necesarios para su localización
                profesional y que la finalidad del tratamiento sea únicamente
                mantener relaciones de cualquier índole con la persona jurídica
                en la que el afectado preste sus servicios.
            </li>
            <li>
                Conservación de los datos: Los datos se conservarán mientras
                dure la relación contractual y posteriormente durante los plazos
                legalmente exigidos.
            </li>
        </ul>
    </section>

    <section class="mt-5">
        <h4>Destinatarios:</h4>

        <p>
            Con carácter general la Empresa no comunicará estos datos personales
            a terceros, con la salvedad de que la prestación de un servicio
            implique la necesidad de una relación contractual con un encargado
            de tratamiento y ello sea estrictamente necesario para gestionar y
            mantener la relación entre el usuario y la mencionada empresa,
            previa autorización expresa por parte del usuario. Esto se hará sólo
            durante el tiempo imprescindible para posibilitar la ejecución del
            contrato de encargo, y en las mismas condiciones y con la misma
            responsabilidad que se le exigen al responsable. Una vez finalizado
            el encargo, el encargado del tratamiento devolverá al Responsable
            los datos personales y suprimirá cualquier copia de la que disponga.
        </p>

        <p>
            Los contenidos, textos, fotografías, diseños, logotipos, imágenes,
            programas de ordenador, códigos fuente y, en general, cualquier
            creación intelectual existente en este sitio, así como el propio
            sitio en su conjunto, como obra artística multimedia, están
            protegidos como derechos de autor por la legislación en materia de
            propiedad intelectual. La EMPRESA es titular de los elementos que
            integran el diseño gráfico del Sitio Web, lo menús, botones de
            navegación, el código HTML, los textos, imágenes, texturas, gráficos
            y cualquier otro contenido del Sitio Web o, en cualquier caso
            dispone de la correspondiente autorización para la utilización de
            dichos elementos. El contenido dispuesto en el Sitio Web no podrá
            ser reproducido ni en todo ni en parte, ni transmitido, ni
            registrado por ningún sistema de recuperación de información, en
            ninguna forma ni en ningún medio, a menos que se cuente con la
            autorización previa, por escrito, de la citada Entidad.
        </p>

        <p>
            Por otra parte, sólo tendrán derecho a acceder a estos datos
            personales los terceros con los que la Empresa tenga una obligación
            legal o contractual de facilitarlos, entre los que se incluyen, por
            ejemplo, los Jueces y Tribunales interesados en los procedimientos
            relacionados con las reclamaciones presentadas.
        </p>

        <p>No están previstas las transferencias internacionales de datos.</p>
    </section>

    <section class="mt-5">
        <h4>Derechos de los interesados:</h4>

        <p>
            El usuario podrá ejercitar en todo momento, en los términos
            establecidos en la legislación vigente, los derechos de acceso,
            rectificación o supresión de datos, solicitar que se limite el
            tratamiento, oponerse al mismo, solicitar la portabilidad de sus
            datos, así como revocar el consentimiento prestado, derechos
            reconocidos en el citado Reglamento (UE). El ejercicio de estos
            derechos puede realizarlo el propio usuario dirigiéndose a la
            Empresa en la dirección de su sede social indicada anteriormente o a
            través del envío de un correo electrónico a la dirección de email
            info@enooby.com. Para el efectivo ejercicio de estos derechos, el
            usuario deberá acreditar su identidad facilitando su nombre y
            apellidos, fotocopia del DNI o documento identificativo equivalente
            que acredite su identidad, petición en la que se concrete la
            solicitud, la dirección a efectos de notificaciones, y fecha y firma
            del solicitante. Asimismo, el usuario podrá reclamar ante la Agencia
            Española de Protección de Datos (AEPD), especialmente cuando no haya
            obtenido satisfacción en el ejercicio de sus derechos, mediante
            escrito dirigido a la misma, a C/. Jorge Juan, nº 6; DP.: 28001 –
            Madrid, o a través de la web: https://www.agpd.es.
        </p>
    </section>
</div>

<app-footer></app-footer>
