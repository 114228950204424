<!-- start Gsuite Video section-->
<section class="section-main">
    <!-- start video popup section-->
    <section class="video-checkout overflow-hidden">
        <div
            class="bg-image video-checkout-bg"
            data-center="transform: translate3d(0px, -50%, 0px)"
            data-top-bottom="transform: translate3d(0px, -25%, 0px)"
            data-bottom-top="transform: translate3d(0px, -75%, 0px)"
        ></div>
        <div class="overlay overlay-three">
            <div class="section-common-space">
                <div class="container">
                    <div class="text-center video-checkout-content-start">
                        <div>
                            ¿Qué podemos hacer
                            <a
                                href="https://www.youtube.com/watch?v=RsPRFBKKPCo&t=2s"
                                itemprop="url"
                                rel="noreferrer"
                                class="show-video mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect btn-highlight"
                            >
                                <!-- <i class="zmdi zmdi-play"></i> -->
                                <i class="fas fa-play"></i>
                            </a>
                            con Google Workspace?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end video popup section-->
</section>
<!-- end Gsuite Video section-->
