import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-twitter-slider',
    templateUrl: './twitter-slider.component.html',
    styleUrls: ['./twitter-slider.component.scss'],
})
export class TwitterSliderComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
