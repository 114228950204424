import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'app-client-slider',
    templateUrl: './client-slider.component.html',
    styleUrls: ['./client-slider.component.scss'],
})
export class ClientSliderComponent implements OnInit {
    carouselOptions = GlobalConstants.singleSlider;
    carouselClients = GlobalConstants.userListSlider;
    constructor() {}

    ngOnInit(): void {}
}
