import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { CarouselModule } from 'ngx-owl-carousel-o';
import {
    RecaptchaModule,
    RECAPTCHA_V3_SITE_KEY,
    RecaptchaV3Module,
} from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderNavComponent } from './_components/header-nav/header-nav.component';
import { HomeComponent } from './_pages/home/home.component';
import { DigitalKitComponent } from './_pages/digital-kit/digital-kit.component';
import { SliderComponent } from './_components/slider/slider.component';
import { AboutUsComponent } from './_components/about-us/about-us.component';
import { WhyChooseUsComponent } from './_components/why-choose-us/why-choose-us.component';
import { GoogleWorkspaceComponent } from './_components/google-workspace/google-workspace.component';
import { GoogleWorkspacePricingComponent } from './_components/google-workspace-pricing/google-workspace-pricing.component';
import { GoogleWorkspaceVideoComponent } from './_components/google-workspace-video/google-workspace-video.component';
import { ServicesComponent } from './_components/services/services.component';
import { ClientSliderComponent } from './_components/client-slider/client-slider.component';
import { TwitterSliderComponent } from './_components/twitter-slider/twitter-slider.component';
import { ContactComponent } from './_components/contact/contact.component';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './_components/footer/footer.component';
import { CookieNoticeComponent } from './_components/cookie-notice/cookie-notice.component';
import { LegalNoticeComponent } from './_pages/legal-notice/legal-notice.component';
import { PrivacyPolicyComponent } from './_pages/privacy-policy/privacy-policy.component';
import { LegalPagesComponent } from './_pages/legal-pages/legal-pages.component';
import { CookieNoticePageComponent } from './_pages/cookie-notice-page/cookie-notice-page.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderNavComponent,
        HomeComponent,
        DigitalKitComponent,
        SliderComponent,
        AboutUsComponent,
        WhyChooseUsComponent,
        GoogleWorkspaceComponent,
        GoogleWorkspacePricingComponent,
        GoogleWorkspaceVideoComponent,
        ServicesComponent,
        ClientSliderComponent,
        TwitterSliderComponent,
        ContactComponent,
        FooterComponent,
        CookieNoticeComponent,
        LegalNoticeComponent,
        PrivacyPolicyComponent,
        LegalPagesComponent,
        CookieNoticePageComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RecaptchaV3Module,
        CarouselModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule
    ],
    exports: [
        ContactComponent,
    ],
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaKey },
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
