<!-- start digital-kit section-->
<section class="digital-kit-header"></section>
<section id="digital-kit" class="section-main">
    <!-- start digital-kit tab section-->
    <section class="digital-kit-section">
        <div class="section-common-space">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- start section title -->
                    <div class="text-center section-heading">
                        <h2 class="section-title title-font">KIT DIGITAL</h2>
                        <h3 class="section-subtitle">
                            Consigue <b>hasta 12.000€</b> para tu empresa gracias a <b>enooby</b> y el kit digital
                        </h3>
                    </div>
                    <!-- end section title -->
                    <div class="digital-kit-tabs" data-action="count-up">
                        <div class="clearfix mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
                            <div class="col-md-12">
                                <div class="text-center">
                                    <!-- start tab menu item-->
                                    <div class="mdl-tabs__tab-bar matx-digital-kit-tabbar mdl-shadow--2dp">
                                        <a href="#tabA" itemprop="url" class="mdl-tabs__tab is-active" >
                                            ¿Qué es el kit digital?
                                        </a>
                                        <a href="#tabB" itemprop="url" class="mdl-tabs__tab" >
                                            ¿Cómo accedo a él?
                                        </a>
                                        <a href="#tabC" itemprop="url" class="mdl-tabs__tab" >
                                            ¿Cómo me ayuda enooby?
                                        </a>
                                    </div>
                                    <!-- end tab menu item-->
                                </div>
                                <div class="matx-tabpanel-wrap">
                                    <!-- start single tab-->
                                    <div class="mdl-tabs__panel animated animateSlow fadeIn matx-digital-kit-tabpanel is-active" id="tabA">
                                        <div class="row tab-title-wrap">
                                            <div class="col-sm-6">
                                                <h3 class="card-title tab-title" >¿QUÉ ES EL KIT DIGITAL?</h3>
                                                <p class="columnd-text">El <b>Kit Digital</b> es un programa de ayudas del Gobierno de España para impulsar la digitalización de pequeñas y medianas empresas.</p>
                                                <p class="columnd-text">Financiado a través de los fondos Next Generation EU de la Unión Europea, dentro del Plan de Recuperación Transformación y Resiliencia, constituyen una magnífica oportunidad para revitalizar tu empresa a través de su transformación digital.</p>
                                                <p class="columnd-text"><b>Enooby</b>, como partner certificado para este proyecto gubernamental puede asesorarte y rejuvenecer tu pyme acompañándote en su potenciación.</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="tab-content text-center">
                                                    <img
                                                        src="assets/img/kit-digital/kit-digital-logo.png"
                                                        alt="kit-digital"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end single tab-->

                                    <!-- start single tab-->
                                    <div class="mdl-tabs__panel animated animateSlow fadeIn matx-digital-kit-tabpanel" id="tabB">
                                        <div class="row tab-title-wrap">
                                            <div class="col-sm-6">
                                                <h3 class="card-title tab-title" >¿Cómo accedo a él?</h3>
                                                <p class="columnd-text">El <b>Kit Digital</b> está dividido en tres segmentos según el tamaño de tu compañía:</p>
                                                <ul>
                                                    <li><b>Segmento I.</b> Pequeñas empresas de entre 10 y menos de 50 empleados. <b>Importe del bono: 12.000€</b></li>
                                                    <li><b>Segmento II.</b> Pequeñas empresas o Microempresas de entre 3 y menos de 10 empleados <b>Importe del bono: 6.000€</b></li>
                                                    <li><b>Segmento III.</b> Pequeñas empresas o Microempresas de entre 1 y menos de 3 empleados y personas en situación de autoempleo <b>Importe del bono: 2.000€</b></li>
                                                </ul>
                                                <p class="columnd-text">Si tu empresa se incluye en estos segmentos, <b>realiza el test de diagnóstico</b>:</p>
                                                <a href="https://www.acelerapyme.es/quieres-conocer-el-grado-de-digitalizacion-de-tu-pyme" class="menu-smooth-scroll mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-default btn-black" target="_blank">Test de diagnóstico</a>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="tab-segmentos">
                                                    <div class="segmento">
                                                        <div><b>Segmento I</b></div>
                                                        <div>Pequeñas empresas de entre 10 y menos de 50 empleados</div>
                                                        <div>12.000€</div>
                                                    </div>
                                                    <div class="segmento">
                                                        <div><b>Segmento II</b></div>
                                                        <div>Pequeñas empresas o Microempresas de entre 3 y menos de 10 empleados</div>
                                                        <div>6.000€</div>
                                                    </div>
                                                    <div class="segmento">
                                                        <div><b>Segmento III</b></div>
                                                        <div>Pequeñas empresas o Microempresas de entre 1 y menos de 3 empleados y personas en situación de autoempleo</div>
                                                        <div>2.000€</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end single tab-->

                                    <!-- start single tab-->
                                    <div class="mdl-tabs__panel animated animateSlow fadeIn matx-digital-kit-tabpanel" id="tabC" >
                                        <div class="row tab-title-wrap">
                                            <div class="col-sm-12">
                                                <h3 class="card-title tab-title" >¿Cómo puede ayudarme enooby?</h3>
                                                <h4>Servicios de oficina virtual</h4>
                                                <p class="columnd-text"><b>Google Workspace</b> (correo electrónico, almacenamiento y trabajo colaborativo)</p>
                                            </div>
                                            <div class="col-sm-6">
                                                
                                                <ul class="kit-pack-mod">
                                                    <b class="kit-pack-mod__title">Licencia Google Workspace Business Standard (12 meses)</b>
                                                    <li>2Tb de espacio para correo electrónico y datos</li>
                                                    <li>Dirección de correo electrónico personalizada (tú@tuempresa.com)</li>
                                                    <li>Calendarios compartidos</li>
                                                    <li>Repositorio de información individual en la nube (Google Drive)</li>
                                                    <li>Repostorios corporativos compartidos de datos (Unidades de Equipo)</li>
                                                    <li>Ofimática en la nube (Google Docs)</li>
                                                    <li>Panel de administración centralizado, para el control de todas las cuentas</li>
                                                    <li>Videoconferencias con hasta 150 personas</li>
                                                    <li>Grabación de videoconferencias por Google Meet</li>
                                                    <li>Cumplimiento normativo en Protección de Datos</li>
                                                    <li>12 meses de asistencia standard</li>
                                                </ul>
                                                <ul class="kit-pack-mod">
                                                    <b class="kit-pack-mod__title">Soporte (12 meses)</b>
                                                    <li>Soporte técnico</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm-6">
                                                <ul class="kit-pack-mod">
                                                    <b class="kit-pack-mod__title">Implantación inicial</b>
                                                    <li>Configuración de registros DNS</li>
                                                    <li>Activación de servicios (Gmail, Contactos, Calendarios, Drive,...)</li>
                                                    <li>Personalización logo corporativo</li>
                                                    <li>Validación del dominio ante Google</li>
                                                    <li>Alta de subdominios para fácil acceso (ej. correo.dominio.com)</li>
                                                    <li>Configuración de seguridad ( SPF, DKIM y DMARC)</li>
                                                    <li>Incorporación de Aviso Legal</li>
                                                    <li>Incorporación de Aviso Legal</li>
                                                    <li>Diseño de plantilla para firma corporativa</li>
                                                </ul>
                                                <ul class="kit-pack-mod">
                                                    <b class="kit-pack-mod__title">Formación</b>
                                                    <li>Consultoría inicial para definir la estrategia (2h)</li>
                                                    <li>Invitación a jornadas periódicas privadas de formación sobre herramientas y funcionalidades</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm-12 text-center">
                                                <small>* Dominio propiedad del cliente</small>
                                                <div class="kit-pack-price">250€ por usuario</div>
                                                <a href="#contacto" itemprop="url" data-target="#contacto" class="menu-smooth-scroll mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-default btn-black">Contáctanos</a>
                                            </div>            
                                        </div>
                                    </div>
                                    <!-- end single tab-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="assets/img/kit-digital/Logo-digitalizadores-01.svg" alt="logo digitalizadores" width="75%" />
                </div>
            </div>
        </div>
    </section>
    <!-- end digital-kit tab section-->
    <app-contact></app-contact>
</section>
<!-- end digital-kit section-->
