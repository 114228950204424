import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    currentYear: number = GlobalConstants.currentYear;
    companyFacebookUrl: string = GlobalConstants.facebookUrl;
    companyTwitterUrl: string = GlobalConstants.twitterUrl;
    companyLinkedinUrl: string = GlobalConstants.linkedinUrl;
    companyLogo: string = GlobalConstants.logoSecondary;

    constructor() {}

    ngOnInit(): void {}
}
