<section class="legal-page">
    <div class="legal-page__title">
        <h1 class="text-white">Política de Cookies</h1>
    </div>
</section>

<div class="container my-5">
    <p>
        El sitio www.enooby.com (en adelante, el “Sitio Web”) es propiedad de
        ENOOBY SPAIN, S.L., con CIF. B-56.019.110 (en adelante, la “EMPRESA”), y
        domicilio en Avda. de la Torrecilla, 16, 1ª Pta. Of. 105; D.P.: 14013 -
        Córdoba.
    </p>

    <section class="mt-5">
        <h4>Qué son las cookies y para qué las utilizamos</h4>
        <p>
            Este sitio web utiliza cookies y/o tecnologías similares que
            almacenan y recuperan información cuando navegas. En general, estas
            tecnologías pueden servir para finalidades muy diversas, como, por
            ejemplo, reconocerte como usuario, obtener información sobre tus
            hábitos de navegación, o personalizar la forma en que se muestra el
            contenido. Los usos concretos que hacemos de estas tecnologías se
            describen a continuación.
        </p>
    </section>

    <section class="mt-5">
        <h4>Tipos de Cookies</h4>

        <ol type="A">
            <li>
                Según la entidad que las gestione:
                <ul>
                    <li>
                        Cookies propias: Son aquellas que se envían al equipo
                        terminal del usuario desde un equipo o dominio
                        gestionado por el propio nuestra Entidad y desde el que
                        se presta el servicio solicitado por el usuario.
                    </li>
                    <li>
                        Cookies de tercero: Son aquellas que se envían al equipo
                        terminal del usuario desde un equipo o dominio que no es
                        gestionado por nuestra Entidad, sino por otra
                        organización que trata los datos obtenidos a través de
                        las cookies.
                    </li>
                </ul>
            </li>
            <li>
                Por su duración:
                <ul>
                    <li>
                        Cookies de sesión: Son cookies temporales que serán
                        almacenadas en el navegador hasta que abandone la página
                        web. Este tipo de cookies sirven par mantener un
                        seguimiento de los movimientos del usuario en el sitio
                        web, de forma que evitará que se pida información que ha
                        sido proporcionada con anterioridad.
                    </li>
                    <li>
                        Cookies persistentes: Son cookies almacenadas en el
                        disco duro y nuestra web las lee cada vez que el usuario
                        acceda a este sitio web. Estas cookies permanecerán
                        almacenadas en el equipo informático del usuario hasta
                        su fecha de expiración determinada en la cual la misma
                        deja de funcionar.
                    </li>
                </ul>
            </li>
            <li>
                Según su finalidad:
                <ul>
                    <li>
                        Cookies técnicas o necesarias: Estas cookies son
                        necesarias para el correcto uso de la página web y la
                        navegación por la misma, permitiendo que sea utilizable
                        activando funciones básicas, así como el acceso a
                        secciones que cuentan con filtros de seguridad. Sin
                        estas cookies, muchos de los servicios disponibles no
                        estarían operativos.
                    </li>
                    <li>
                        Cookies de preferencias o personalización: Estas cookies
                        nos permiten recordar la información relevante para
                        poder diferenciar a los usuarios y ofrecerles una
                        experiencia personal, así como crear un perfil basado en
                        sus hábitos de navegación. Entre estas cookies se
                        encuentran el idioma, el número de resultados a mostrar
                        cuando el usuario realiza una búsqueda, el aspecto o
                        contenido del servicio en función del tipo de navegador
                        o de la región desde la que accede al servicio, etc.).
                    </li>
                    <li>
                        Cookies de análisis o medición: Estas cookies nos
                        permiten el seguimiento y análisis del uso de nuestra
                        web por los usuarios. La información recogida mediante
                        este tipo de cookies la utilizamos para cuantificar los
                        impactos de los anuncios incluidos y medir la actividad
                        de la web, todo ello para poder introducir mejoras en
                        función de esos datos.
                    </li>
                    <li>
                        Cookies de publicidad: Estas cookies almacenan
                        información del comportamiento de los usuarios obtenida
                        a través de la observación continuada de sus hábitos de
                        navegación, lo que permite ofrecer publicidad dirigida y
                        personalizada en función del perfil de los usuarios u
                        ofrecerles publicidad no personalizada cuando no
                        contemos con dicho perfil. Rechazar estas cookies no
                        significa que el usuario vea menos publicidad, pero la
                        publicidad a la que acceda será más genérica y menos
                        enfocada a sus gustos.
                    </li>
                </ul>
            </li>
        </ol>

        <p>
            El usuario acepta expresamente, por la utilización de este Site, el
            tratamiento de la información recabada en la forma y con los fines
            anteriormente mencionados. Y asimismo, reconoce conocer la
            posibilidad de rechazar el tratamiento de tales datos o información
            rechazando el uso de Cookies mediante la selección de la
            configuración apropiada a tal fin en su navegador. Si bien esta
            opción de bloqueo de Cookies en su navegador puede no permitirle el
            uso pleno de todas las funcionalidades del Website.
        </p>
        <p>
            El usuario puede informarse de las transferencias a terceros países
            que, en su caso, realizan los terceros identificados en este
            política de cookies en sus correspondientes políticas (ver los
            enlaces facilitados en el apartado “Proveedor” del anterior cuadro
            de Cookies).
        </p>
        <p>
            Para el resto de información exigida por el artículo 13 del RGPD, el
            usuario podrá consultarla en nuestra
            <a routerLink="/privacy-policy">“Política de Privacidad”</a>
        </p>
    </section>

    <section class="mt-5">
        <h4>¿Se pueden desactivar o eliminar las cookies?</h4>
        <p>
            Usted puede rechazar el tratamiento de los datos o información
            recogidos por las cookies en cualquier momento, mediante la
            “Configuración de las cookies” (enlazar con el apartado 3), así como
            por las opciones de su navegador de Internet. Puede permitir,
            bloquear (de forma general o particular para un dominio específico)
            o eliminar las cookies instaladas en su equipo.
        </p>
        <p>Para más información sobre Firefox pulse aquí:</p>
        <p>
            <a
                href="http://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-#w_configurar-las-cookies"
                rel="noreferrer"
                target="_blank"
            >
                http://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-#w_configurar-las-cookies.
            </a>
        </p>

        <p>Para más información sobre Chrome pulse aquí:</p>
        <p>
            <a
                href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
                rel="noreferrer"
                target="_blank"
                >http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a
            >
        </p>

        <p>Para más información sobre Explorer pulse aquí:</p>
        <p>
            <a
                href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
                rel="noreferrer"
                target="_blank"
            >
                https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
        </p>
        <p>Para más información sobre Safari pulse aquí:</p>
        <p>
            <a
                href="http://support.apple.com/kb/ph5042"
                target="_blank"
                rel="noreferrer"
                >http://support.apple.com/kb/ph5042</a
            >
        </p>
        <p>Para más información sobre Opera pulse aquí:</p>
        <p>
            <a
                href="http://help.opera.com/Windows/11.50/esES/cookies.html"
                rel="noreferrer"
                target="_blank"
                >http://help.opera.com/Windows/11.50/esES/cookies.html</a
            >
        </p>
    </section>
</div>
