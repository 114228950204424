import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-cookie-notice',
    templateUrl: './cookie-notice.component.html',
    styleUrls: ['./cookie-notice.component.scss'],
})
export class CookieNoticeComponent implements OnInit {
    isAccepted: boolean = false;
    constructor(private cookieService: CookieService) {}

    ngOnInit(): void {}

    acceptCookies() {
        this.cookieService.set('acceptCookies', 'true');
        this.isAccepted = true;
    }
}
