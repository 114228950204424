<section class="section-main" id="licencias">
    <section class="secondary-bg-color pricing-section">
        <div class="section-common-space">
            <div class="container">
                <div class="text-center section-heading">
                    <h2 class="section-title title-font">
                        Licencias de Google Workspace
                    </h2>
                    <h3 class="section-subtitle">
                        Compara las diferentes versiones existentes
                    </h3>
                </div>

                <div class="row justify-content-center">
                    <div
                        *ngFor="let product of productPricing"
                        class="col-md-4 single-pricing-table"
                        itemscope=""
                        itemtype="http://schema.org/Offer"
                    >
                        <div
                            class="primary-bg-color pricing-table jumbo-shadow mdl-shadow--2dp"
                        >
                            <div class="text-left pct-header">
                                <div class="plan-price">
                                    <span class="price" itemprop="price">{{
                                        product.price
                                    }}</span>
                                    <span class="currency">€</span>
                                    <small class="iva">+ IVA</small>
                                </div>
                                <div class="licence-type" itemprop="name">
                                    {{ product.name }}
                                </div>
                            </div>
                            <div class="pct-body">
                                <ul class="plan-feature">
                                    <li *ngFor="let itemlist of product.list">
                                        {{ itemlist }}
                                    </li>
                                </ul>
                            </div>
                            <div class="pc-foot text-center">
                                <a
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-black btn-default"
                                    [href]="product.url"
                                    itemprop="url"
                                    rel="noreferrer"
                                    target="_blank"
                                    data-upgraded=",MaterialButton,MaterialRipple"
                                    >MÁS INFORMACIÓN<span
                                        class="mdl-button__ripple-container"
                                        ><span class="mdl-ripple"></span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
                <p>
                    * Costes de implantación, migración y formación no
                    incluidos. Póngase en contacto con nosotros y le
                    informaremos sin compromiso.
                </p>
            </div>
        </div>
    </section>
</section>
