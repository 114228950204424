import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

export class GlobalConstants {
    public static appName: string = 'Enooby';
    public static slogan: string = 'Innovation into Bussiness';
    public static logoPrimary: string = '/assets/img/app/logo_enooby_firma.png';
    public static logoSecondary: string =
        '/assets/img/app/logo_enooby_blanco.png';

    // SOCIAL
    public static socialImage: string = '/assets/img/app/social.jpg';
    public static socialDescription: string =
        'Asesoría Tecnológica especializada en Correo Electrónico y Herramientas de Colaboración. Distribuidor oficial de Google Workspace en Córdoba. Gmail, Drive, Calendar y mucho más.';
    public static socialKeywords =
        'Google Córdoba, Google Cordoba, G Suite Córdoba, G Suite Cordoba, Google for Work Córdoba, Google for Education Córdoba, Google Apps Córdoba, Google Apps Cordoba, Gmail Córdoba, G Suite for Business, G Suite Basic, Google, Google Córdoba, Calendar, Licencias G Suite, Implantador G Suite, Implantador G Suite Córdoba, Experto G Suite, Experto G Suite Córdoba, Enooby, Formación Google, Correo Electrónico, Correo Empresarial, Correo Seguro, Correo Colaborativo, Herramientas Colaborativas, Videoconferencia, Colaboración, Hangouts, Groups, Sites, Grupos, Sitios, Jornadas Google, Jornadas G Suite, Eventos, Productividad, Seguridad, Desarrollo, Inovación, Migraciones, Migración, Distribuidor oficial G Suite, G Suite Partner, Google Partner, Cursos G Suite, Córdoba, Cádiz, Sevilla, Granada, Jaén, Málaga, g suite, Google Workspace, Distribuidor';

    // SOCIAL LINKS
    public static facebookUrl: string =
        'https://www.facebook.com/enooby.spain/';
    public static twitterUrl: string = 'https://twitter.com/enooby';
    public static linkedinUrl: string =
        'https://es.linkedin.com/company/enooby';
    public static phoneNumber: string = '957119911';
    public static twitterUser: string = '@enooby';

    // COMPANY
    public static companyName: string = 'Enooby Spain S.L.';

    public static theme: string = 'theme-light';

    public static currentYear = new Date().getFullYear();

    // API BASE URL
    // public static apiBase: string = `${environment.apiUrl}/api`;

    // General Config
    public static tokenExpires: number = 60; // Time in minutes

    public static googleProducts = [
        {
            url: 'https://workspace.google.es/intl/es/products/gmail/',
            image: '/assets/img/google-workspace/gmail.png',
            name: 'Gmail',
            short_description: 'Correo electrónico de empresa personalizado',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/docs/',
            image: '/assets/img/google-workspace/docs.png',
            name: 'Documentos',
            short_description: 'Procesamiento de textos',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/keep/',
            image: '/assets/img/google-workspace/keep.png',
            name: 'Keep',
            short_description: 'Notas y listas',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/meet/',
            image: '/assets/img/google-workspace/meet.png',
            name: 'Meet',
            short_description: 'Llamadas de voz y videoconferencias',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/sheets/',
            image: '/assets/img/google-workspace/sheets.png',
            name: 'Hojas de Cálculo',
            short_description: 'Hojas de cálculo',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/apps-script/',
            image: '/assets/img/google-workspace/apps_script.png',
            name: 'Apps Script',
            short_description: 'Optimiza tu forma de trabajar',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/chat/',
            image: '/assets/img/google-workspace/chat.png',
            name: 'Chat',
            short_description: 'Mensajeria para equipos',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/slides/',
            image: '/assets/img/google-workspace/slides.png',
            name: 'Presentaciones',
            short_description: 'Crea presentaciones',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/cloud-search/',
            image: '/assets/img/google-workspace/cloud_search.png',
            name: 'Cloud Search',
            short_description: 'Búsqueda inteligente en todo Google Workspace',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/forms/',
            image: '/assets/img/google-workspace/forms.png',
            name: 'Formularios',
            short_description: 'Crea encuestas',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/calendar/',
            image: '/assets/img/google-workspace/calendar.png',
            name: 'Calendar',
            short_description: 'Calendarios compartidos',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/sites/',
            image: '/assets/img/google-workspace/sites.png',
            name: 'Sites',
            short_description: 'Crea sitios web',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/drive/',
            image: '/assets/img/google-workspace/drive.png',
            name: 'Drive',
            short_description: 'Almacenamiento en la nube',
        },
    ];

    public static googleProductsSecurity = [
        {
            url: 'https://workspace.google.es/intl/es/products/admin/',
            image: '/assets/img/google-workspace/admin.png',
            name: 'Consola de Administración',
            short_description: 'Constorles de seguridad y administración',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/admin/endpoint/',
            image: '/assets/img/google-workspace/apps_policy.png',
            name: 'Punto de conexión',
            short_description: 'Crea sitios web',
        },
        {
            url: 'https://workspace.google.es/intl/es/products/vault/',
            image: '/assets/img/google-workspace/vault.png',
            name: 'Vault',
            short_description: 'Conserva, archiva y busca datos',
        },
    ];

    public static googleProductPricing = [
        {
            name: 'Cuenta Gmail',
            price: '0',
            list: [
                'Dominio @gmail.com',
                'Solución doméstica (no profesional)',
                'Videollamadas de hasta 100 participantes y 60 minutos',
                '15Gb de almacenamiento en la nube por usuario',
                'Sin controles de seguridad y gestión',
                'Sin asistencia personalizada',
            ],
            url: 'https://www.google.com/intl/es/gmail/about/',
        },
        {
            name: 'Google Workspace para Organizaciones sin Ánimo de Lucro',
            price: '0',
            list: [
                'Dominio personalizado',
                'Correo electrónico de empresa personalizado y seguro',
                'Videollamadas de hasta 100 participantes',
                '30Gb de almacenamiento en la nube por usuario',
                'Controles de seguridad y gestión',
                'Asistencia estándar',
            ],
            url: 'https://www.google.com/intl/es/nonprofits/offerings/g-suite/',
        },
        {
            name: 'Google Workspace para Educación',
            price: '0',
            list: [
                'Google Workspace para Educación',
                'Correo electrónico de empresa personalizado y seguro',
                'Videollamadas de hasta 100 participantes y grabación de vídeo',
                'Almacenamiento en la nube ilimitado por usuario',
                'Controles de seguridad y gestión',
                'Asistencia estándar (posibilidad de contratar asistencia mejorada de pago)',
            ],
            url: 'https://workspace.google.es/intl/es/pricing.html',
        },
        {
            name: 'Google Workspace Business Starter (por usuario al mes)',
            price: '5.20',
            list: [
                'Dominio personalizado',
                'Correo electrónico de empresa personalizado y seguro',
                'Videollamadas de hasta 100 participantes',
                '30Gb de almacenamiento en la nube por usuario',
                'Controles de seguridad y gestión',
                'Asistencia estándar',
            ],
            url: 'https://workspace.google.es/intl/es/pricing.html',
        },
        {
            name: 'Google Workspace Business Standard (por usuario por mes)',
            price: '10.40',
            list: [
                'Dominio personalizado',
                'Correo electrónico de empresa personalizado y seguro',
                'Videollamadas de hasta 150 participantes y grabación de vídeo',
                '2Tb de almacenamiento en la nube por usuario',
                'Controles de seguridad y gestión',
                'Asistencia estándar (posibilidad de contratar asistencia mejorada de pago)',
            ],
            url: 'https://workspace.google.es/intl/es/pricing.html',
        },
    ];

    // -----------------------------
    // OWL CAROUSEL
    // -----------------------------
    // User Slider
    public static userSlider: OwlOptions = {
        loop: false,
        margin: 20,
        nav: false,
        lazyLoad: true,
        items: 1,
        autoplay: false,
        dots: false,
        autoplayTimeout: 4000,
    };

    // User List Slider
    public static userListSlider: OwlOptions = {
        loop: true,
        margin: 20,
        nav: false,
        stagePadding: 30,
        lazyLoad: true,
        items: 4,
        autoplay: false,
        dots: false,
        autoplayTimeout: 4000,
    };

    // Single Slider
    public static singleSlider: OwlOptions = {
        loop: true,
        margin: 20,
        nav: false,
        lazyLoad: true,
        items: 1,
        autoplay: true,
        autoplayTimeout: 4000,
    };

    // Single Slider Boxed
    public static singleSliderBoxed: OwlOptions = {
        loop: true,
        stagePadding: 30,
        margin: 20,
        nav: false,
        dots: false,
        lazyLoad: true,
        items: 1,
        autoplay: true,
        autoplayTimeout: 4000,
    };

    // Cover Slider
    public static coverSlider: OwlOptions = {
        loop: true,
        margin: 0,
        nav: false,
        lazyLoad: true,
        items: 1,
        autoplay: true,
        autoplayTimeout: 6000,
    };

    // Double Slider
    public static doubleSlider: OwlOptions = {
        loop: true,
        margin: 20,
        stagePadding: 20,
        nav: false,
        dots: false,
        lazyLoad: false,
        items: 2,
        autoplay: false,
        autoplayTimeout: 4000,
    };

    // Task Slider
    public static taskSlider: OwlOptions = {
        loop: true,
        margin: 20,
        nav: false,
        stagePadding: 50,
        lazyLoad: true,
        items: 2,
        autoplay: false,
        autoplayTimeout: 4000,
    };
}
