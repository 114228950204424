<section class="legal-page">
    <div class="legal-page__title">
        <h1 class="text-white">Aviso Legal</h1>
    </div>
</section>

<div class="container my-5">
    <p>
        El sitio www.enooby.com (en adelante, el “Sitio Web”) es propiedad de
        ENOOBY SPAIN, S.L., con CIF. B-56.019.110 (en adelante, la “EMPRESA”),
        Registro Mercantil de Córdoba, Folio 171, Tomo 2446, Hoja CO-35421,
        Inscripción 1ª y domicilio en Avda. de la Torrecilla, 16, 1ª Pta. Of.
        105; D.P.: 14013 - Córdoba.
    </p>
    <p>
        La EMPRESA le da la bienvenida y le invita a leer atentamente las
        Condiciones Generales de Uso de este Sito Web (en adelante, las
        “Condiciones Generales de Uso”) que describen los términos y condiciones
        que serán aplicables a su navegación por el mismo, de conformidad con lo
        establecido en la normativa española de aplicación. Dado que la EMPRESA
        podría modificar en el futuro estas Condiciones de Uso, le recomendamos
        que las visite periódicamente para estar debidamente informado de los
        cambios realizados.
    </p>

    <p>
        Con el ánimo de que el uso del Sitio Web se ajuste a criterios de
        transparencia, claridad y sencillez, la EMPRESA informa al Usuario que
        cualquier sugerencia, duda o consulta sobre las Condiciones Generales de
        Uso será recibida y solucionada contactando con la EMPRESA a través del
        correo electrónico: info@enooby.com
    </p>

    <section class="mt-5">
        <h4>1. OBJETO</h4>
        <p>
            La EMPRESA suministra el contenido y los servicios que están
            disponibles en el Sitio Web, con sujeción a las presentes
            Condiciones Generales de Uso así como a la política sobre
            tratamiento de datos personales (en adelante, la “Política de
            Protección de Datos”). El acceso a este Sitio Web o su utilización
            en cualquier forma le otorga la calificación de “Usuario” e implica
            la aceptación sin reservas de todas y cada una de las presentes
            Condiciones Generales de Uso, reservándose la EMPRESA el derecho a
            modificarlos en cualquier momento. En consecuencia, será
            responsabilidad de todo Usuario, la atenta lectura de las
            Condiciones Generales de Uso vigente en cada una de las ocasiones en
            que acceda a este Sitio Web, por lo que si éste no está de acuerdo
            con cualquiera de los mismos aquí dispuestos, deberá abstenerse
            respecto al uso del presente Sitio Web.
        </p>
        <p>
            Asimismo, queda advertido de que, en ocasiones, se podrán establecer
            condiciones particulares para la utilización en el Sitio Web de
            contenidos y/o servicios específicos, la utilización de dichos
            contenidos o servicios implicará la aceptación de las condiciones
            particulares en ellos especificadas.
        </p>
    </section>

    <section class="mt-5">
        <h4>2. SERVICIOS</h4>

        <p>
            A través del Sitio Web, la EMPRESA informa a los Usuarios la función
            principal de su actividad relacionada con los servicios de google
            cloud partner, la asesoría tecnológica y la mejora de la
            productividad en sus clientes en el campo de las nuevas tecnologías.
            La web dispone de un apartado denominado “Nosotros” que describe la
            actividad de la empresa, la misión, el equipo de trabajo con el que
            cuenta y la ventaja que tienen al ser google cloud partners
            oficiales de España; un apartado denominado “G Suite” que es
            utilizado por la empresa para automatizar, dar soluciones muy
            rápidas y trabajar eficazmente desde cualquier dispositivo usado por
            las compañías; “Servicios” es el apartado en el que se puede acceder
            al catálogo de los servicios que ofrecen, entre ellos están:
            consultoría, implementación y migración, soporte, formación y
            desarrollo; y, finalmente el apartado “Contacto”, donde los
            interesados pueden dejar sus datos para contactar con la empresa
            administradora de la web, aparece la dirección, email y número de
            teléfono.
        </p>
    </section>

    <section class="mt-5">
        <h4>3. PROPIEDAD INDUSTRIAL E INTELECTUAL</h4>

        <p>
            El Usuario reconoce y acepta que todos los contenidos que se
            muestran en el Sitio Web y en especial, diseños, textos, imágenes,
            logos, iconos, botones, software, nombres comerciales, marcas, o
            cualesquiera otros signos susceptibles de utilización industrial y/o
            comercial están sujetos a derechos de Propiedad Intelectual y todas
            las marcas, nombres comerciales o signos distintivos, todos los
            derechos de propiedad industrial e intelectual, sobre los contenidos
            y/o cualesquiera otros elementos insertados en el página, que son
            propiedad exclusiva de la EMPRESA y/o de terceros, quienes tienen el
            derecho exclusivo de utilizarlos en el tráfico económico. Por todo
            ello el Usuario se compromete a no reproducir, copiar, distribuir,
            poner a disposición o de cualquier otra forma comunicar
            públicamente, transformar o modificar tales contenidos manteniendo
            indemne a la EMPRESA de cualquier reclamación que se derive del
            incumplimiento de tales obligaciones. En ningún caso el acceso al
            Sitio Web implica ningún tipo de renuncia, transmisión, licencia o
            cesión total ni parcial de dichos derechos, salvo que se establezca
            expresamente lo contrario. Las presentes Condiciones Generales de
            Uso del Sitio Web no confieren a los Usuarios ningún otro derecho de
            utilización, alteración, explotación, reproducción, distribución o
            comunicación pública del Sitio Web y/o de sus Contenidos distintos
            de los aquí expresamente previstos. Cualquier otro uso o explotación
            de cualesquiera derechos estará sujeto a la previa y expresa
            autorización específicamente otorgada a tal efecto por la EMPRESA o
            el tercero titular de los derechos afectados.
        </p>

        <p>
            Los contenidos, textos, fotografías, diseños, logotipos, imágenes,
            programas de ordenador, códigos fuente y, en general, cualquier
            creación intelectual existente en este sitio, así como el propio
            sitio en su conjunto, como obra artística multimedia, están
            protegidos como derechos de autor por la legislación en materia de
            propiedad intelectual. La EMPRESA es titular de los elementos que
            integran el diseño gráfico del Sitio Web, lo menús, botones de
            navegación, el código HTML, los textos, imágenes, texturas, gráficos
            y cualquier otro contenido del Sitio Web o, en cualquier caso
            dispone de la correspondiente autorización para la utilización de
            dichos elementos. El contenido dispuesto en el Sitio Web no podrá
            ser reproducido ni en todo ni en parte, ni transmitido, ni
            registrado por ningún sistema de recuperación de información, en
            ninguna forma ni en ningún medio, a menos que se cuente con la
            autorización previa, por escrito, de la citada Entidad.
        </p>

        <p>
            Asimismo queda prohibido suprimir, eludir y/o manipular el
            "copyright" así como los dispositivos técnicos de protección, o
            cualesquiera mecanismos de información que pudieren contener los
            contenidos. El Usuario de este Sitio Web se compromete a respetar
            los derechos enunciados y a evitar cualquier actuación que pudiera
            perjudicarlos, reservándose en todo caso la EMPRESA el ejercicio de
            cuantos medios o acciones legales le correspondan en defensa de sus
            legítimos derechos de propiedad intelectual e industrial.
        </p>
    </section>

    <section class="mt-5">
        <h4>4. OBLIGACIONES Y RESPONSABILIDADES DEL USUARIO DEL SITIO WEB</h4>

        <p>El Usuario se compromete a:</p>
        <p>
            Hacer un uso adecuado y lícito del Sitio Web así como de los
            contenidos y servicios, de conformidad con: (i) la legislación
            aplicable en cada momento; (ii) las Condiciones Generales de Uso del
            Sitio Web; (iii) la moral y buenas costumbres generalmente aceptadas
            y (iv) el orden público.
        </p>
        <p>
            Proveerse de todos los medios y requerimientos técnicos que se
            precisen para acceder al Sitio Web.
        </p>

        <p>
            Facilitar información veraz al cumplimentar con sus datos de
            carácter personal los formularios contenidos en el Sitio Web y a
            mantenerlos actualizados en todo momento de forma que responda, en
            cada momento, a la situación real del Usuario. El Usuario será el
            único responsable de las manifestaciones falsas o inexactas que
            realice y de los perjuicios que cause a la EMPRESA o a terceros por
            la información que facilite. No obstante lo establecido en el
            apartado anterior el Usuario deberá asimismo abstenerse de:
        </p>

        <ol class="ml-4" type="a">
            <li>
                Hacer un uso no autorizado o fraudulento del Sitio Web y/o de
                los contenidos con fines o efectos ilícitos, prohibidos en las
                presentes Condiciones Generales de Uso, lesivos de los derechos
                e intereses de terceros, o que de cualquier forma puedan dañar,
                inutilizar, sobrecargar, deteriorar o impedir la normal
                utilización de los servicios o los documentos, archivos y toda
                clase de contenidos almacenados en cualquier equipo informático.
            </li>
            <li>
                Acceder o intentar acceder a recursos o áreas restringidas del
                Sitio Web, sin cumplir las condiciones exigidas para dicho
                acceso.
            </li>
            <li>
                Provocar daños en los sistemas físicos o lógicos del Sitio Web,
                de sus proveedores o de terceros.
            </li>
            <li>
                Introducir o difundir en la red virus informáticos o
                cualesquiera otros sistemas físicos o lógicos que sean
                susceptibles de provocar daños en los sistemas físicos o lógicos
                de la EMPRESA, de sus proveedores o de terceros.
            </li>
            <li>
                Intentar acceder, utilizar y/o manipular los datos de la
                EMPRESA, terceros proveedores y otros Usuarios.
            </li>
            <li>
                Reproducir o copiar, distribuir, permitir el acceso del público
                a través de cualquier modalidad de comunicación pública,
                transformar o modificar los contenidos, a menos que se cuente
                con la autorización del titular de los correspondientes derechos
                o ello resulte legalmente permitido.
            </li>
            <li>
                Suprimir, ocultar o manipular las notas sobre derechos de
                propiedad intelectual o industrial y demás datos identificativos
                de los derechos de la EMPRESA o de terceros incorporados a los
                contenidos, así como los dispositivos técnicos de protección o
                cualesquiera mecanismos de información que puedan insertarse en
                los contenidos.
            </li>
            <li>
                Obtener e intentar obtener los contenidos empleando para ello
                medios o procedimientos distintos de los que, según los casos,
                se hayan puesto a su disposición a este efecto o se hayan
                indicado expresamente en las páginas web donde se encuentren los
                contenidos o, en general, de los que se empleen habitualmente en
                Internet por no entrañar un riesgo de daño o inutilización del
                sitio web y/o de los contenidos.
            </li>
            <li>
                En particular, y a título meramente indicativo y no exhaustivo,
                el Usuario se compromete a no transmitir, difundir o poner a
                disposición de terceros informaciones, datos, contenidos,
                mensajes, gráficos, dibujos, archivos de sonido y/o imagen,
                fotografías, grabaciones, software y, en general, cualquier
                clase de material que:
                <ol class="ml-4" type="1">
                    <li>
                        De cualquier forma sea contrario, menosprecie o atente
                        contra los derechos fundamentales y las libertades
                        públicas reconocidas constitucionalmente, en los
                        Tratados Internacionales y en el resto de la legislación
                        vigente.
                    </li>
                    <li>
                        Induzca, incite o promueva actuaciones delictivas,
                        denigratorias, difamatorias, violentas o, en general,
                        contrarias a la ley, a la moral, a las buenas costumbres
                        generalmente aceptadas o al orden público.
                    </li>
                    <li>
                        Induzca, incite o promueva actuaciones, actitudes o
                        pensamientos discriminatorios por razón de sexo, raza,
                        religión, creencias, edad o condición.
                    </li>
                    <li>
                        Incorpore, ponga a disposición o permita acceder a
                        productos, elementos, mensajes y/o servicios delictivos,
                        violentos, ofensivos, nocivos, degradantes o, en
                        general, contrarios a la ley, a la moral y a las buenas
                        costumbres generalmente aceptadas o al orden público.
                    </li>
                    <li>
                        Induzca o pueda inducir a un estado inaceptable de
                        ansiedad o temor.
                    </li>
                    <li>
                        Induzca o incite a involucrarse en prácticas peligrosas,
                        de riesgo o nocivas para la salud y el equilibrio
                        psíquico.
                    </li>
                    <li>
                        Se encuentra protegido por la legislación en materia de
                        protección intelectual o industrial perteneciente a la
                        EMPRESA o a terceros sin que haya sido autorizado el uso
                        que se pretenda realizar.
                    </li>
                    <li>
                        Sea contrario al honor, a la intimidad personal y
                        familiar o a la propia imagen de las personas.
                    </li>
                    <li>Constituya cualquier tipo de publicidad.</li>
                    <li>
                        Incluya cualquier tipo de virus o programa que impida el
                        normal funcionamiento del Sitio Web.
                    </li>
                </ol>
            </li>
        </ol>

        <p>
            Si para acceder a algunos de los servicios y/o contenidos del Sitio
            Web, se le proporcionara una contraseña, se obliga a usarla de
            manera diligente, manteniéndola en todo momento en secreto. En
            consecuencia, será responsable de su adecuada custodia y
            confidencialidad, comprometiéndose a no cederla a terceros, de
            manera temporal o permanente, ni a permitir el acceso a los
            mencionados servicios y/o contenidos por parte de personas ajenas.
            Igualmente, se obliga a notificar a la EMPRESA cualquier hecho que
            pueda suponer un uso indebido de su contraseña, como, a título
            enunciativo, su robo, extravío o el acceso no autorizado, con el fin
            de proceder a su inmediata cancelación. En consecuencia, mientras no
            efectúe la notificación anterior, la EMPRESA quedará eximida de
            cualquier responsabilidad que pudiera derivarse del uso indebido de
            su contraseña, siendo de su responsabilidad cualquier utilización
            ilícita de los contenidos y/o servicios del Sitio Web por cualquier
            tercero ilegítimo.
        </p>

        <p>
            Si de manera negligente o dolosa incumpliera cualquiera de las
            obligaciones establecidas en las presentes Condiciones Generales de
            Uso, responderá por todos los daños y perjuicios que de dicho
            incumplimiento pudieran derivarse para la EMPRESA.
        </p>
    </section>

    <section class="mt-5">
        <h4>5. RESPONSABILIDADES</h4>

        <p>
            La EMPRESA no garantiza el acceso continuado, ni la correcta
            visualización, descarga o utilidad de los elementos e informaciones
            contenidas en las páginas del sitio Web, que pueden verse impedidos,
            dificultados o interrumpidos por factores o circunstancias que están
            fuera de su control.
        </p>

        <p>
            La EMPRESA no se hace responsable de las decisiones que pudieran
            adoptarse como consecuencia del acceso a los contenidos o
            informaciones ofrecidas.
        </p>

        <p>
            La EMPRESA podrá interrumpir el servicio o resolver de modo
            inmediato la relación con el Usuario si detecta que un uso de su
            Sitio Web o de cualquiera de los servicios ofertados en el mismo son
            contrarios a las presentes Condiciones Generales de Uso. La EMPRESA
            no se hace responsable por daños, perjuicios, pérdidas,
            reclamaciones o gastos derivados del uso del Sitio Web. Únicamente
            será responsable de eliminar, lo antes posible, los contenidos que
            puedan generar tales perjuicios, siempre que así se notifique. En
            especial no será responsable de los perjuicios que se pudieran
            derivar, entre otros, de:
        </p>

        <ol class="ml-4" type="a">
            <li>
                Interferencias, interrupciones, fallos, omisiones, averías
                telefónicas, retrasos, bloqueos o desconexiones en el
                funcionamiento del sistema electrónico, motivadas por
                deficiencias, sobrecargas y errores en las líneas y redes de
                telecomunicaciones, o por cualquier otra causa ajena al control
                de la EMPRESA.
            </li>
            <li>
                Intromisiones ilegítimas mediante el uso de programas malignos
                de cualquier tipo y a través de cualquier medio de comunicación,
                tales como virus informáticos o cualesquiera otros.
            </li>
            <li>Abuso indebido o inadecuado del Sitio Web.</li>
            <li>
                Errores de seguridad o navegación producidos por un mal
                funcionamiento del navegador o por el uso de versiones no
                actualizadas del mismo. Los administradores de la EMPRESA se
                reservan el derecho de retirar, total o parcialmente, cualquier
                contenido o información presente en el Sitio Web.
            </li>
        </ol>

        <p>
            La EMPRESA excluye cualquier responsabilidad por los daños y
            perjuicios de toda naturaleza que pudieran deberse a la mala
            utilización de los servicios de libre disposición y uso por parte de
            los Usuarios de Sitio Web. Asimismo, la EMPRESA queda exonerada de
            cualquier responsabilidad por el contenido e informaciones que
            puedan ser recibidas como consecuencia de los formularios de
            recogida de datos, estando los mismos únicamente para la prestación
            de los servicios de consultas y dudas. Por otro lado, en caso de
            causar daños y perjuicios por un uso ilícito o incorrecto de dichos
            servicios, podrá ser el Usuario reclamado por la EMPRESA de los
            daños o perjuicios causados.
        </p>

        <p>
            Usted defenderá, indemnizará y mantendrá a la EMPRESA indemne frente
            a cualesquiera daños y perjuicios que se deriven de reclamaciones,
            acciones o demandas de terceros como consecuencia de su acceso o uso
            del Sitio Web. Asimismo, usted se obliga a indemnizar a la EMPRESA
            frente a cualesquiera daños y perjuicios, que se deriven del uso por
            su parte de “robots”, “spiders”, “crawlers” o herramientas similares
            empleadas con el fin de recabar o extraer datos o de cualquier otra
            actuación por su parte que imponga una carga irrazonable sobre el
            funcionamiento del Sitio Web.
        </p>
    </section>

    <section class="mt-5">
        <h4>6. HIPERVÍNCULOS</h4>

        <p>
            El Usuario se obliga a no reproducir de ningún modo, ni siquiera
            mediante un hiperenlace o hipervínculo, el Sitio Web de la EMPRESA,
            así como ninguno de sus contenidos, salvo autorización expresa y por
            escrito de la EMPRESA.
        </p>
        <p>
            El Sitio Web de la EMPRESA incluye enlaces a otras sitios web
            gestionados por terceros, con objeto de facilitar el acceso del
            Usuario a la información de empresas colaboradoras y/o
            patrocinadoras. Conforme con ello, la EMPRESA no se responsabiliza
            del contenido de dichos sitios web, ni se sitúa en una posición de
            garante ni/o de parte ofertante de los servicios y/o información que
            se puedan ofrecer a terceros a través de los enlaces de terceros.
        </p>
        <p>
            Se concede al Usuario un derecho limitado, revocable y no exclusivo
            a crear enlaces a la página principal del Sitio Web exclusivamente
            para uso privado y no comercial. Los sitios web que incluyan enlace
            a nuestro Sitio Web (I) no podrán dar a entender que la EMPRESA
            recomienda ese sitio web o sus servicios o productos; (II) no podrán
            falsear su relación con la EMPRESA ni afirmar que la EMPRESA ha
            autorizado tal enlace, ni incluir marcas, denominaciones, nombres
            comerciales, logotipos u otros signos distintivos de la EMPRESA;
            (III) no podrán incluir contenidos que puedan considerarse de mal
            gusto, obscenos, ofensivos, controvertidos, que inciten a la
            violencia o la discriminación por razón de sexo, raza o religión,
            contrarios al orden público o ilícitos. (IV) no podrán enlazar a
            ninguna página del Sitio Web distinta de la página principal; (V)
            deberá enlazar con la propia dirección del Sitio Web, sin permitir
            que el sitio web que realice el enlace reproduzca el Sitio Web como
            parte de su web o dentro de uno de sus “frames” o crear un “browser”
            sobre cualquiera de las páginas del Sitio Web. La EMPRESA podrá
            solicitar, en cualquier momento, que elimine cualquier enlace al
            Sitio Web, después de lo cual deberá proceder de inmediato a su
            eliminación. La EMPRESA no puede controlar la información,
            contenidos, productos o servicios facilitados por otros sitios web
            que tengan establecidos enlaces con destino al Sitio Web.
        </p>
        <p>
            En consecuencia, la EMPRESA no asume ningún tipo de responsabilidad
            por cualquier aspecto relativo a tales sitios web.
        </p>
    </section>

    <section class="mt-5">
        <h4>7. PROTECCIÓN DE DATOS</h4>
        <p>
            Para utilizar algunos de los Servicios, los Usuarios deben
            proporcionar previamente ciertos datos de carácter personal. Para
            ello, la EMPRESA tratará automatizadamente los Datos Personales en
            cumplimiento con el Reglamento General de Protección de Datos
            2016/679 (UE) de 27 de Abril. Para ello, el Usuario puede acceder a
            la política seguida en el tratamiento de los datos personales así
            como el establecimiento de las finalidades previamente establecidas,
            a lo dispuesto en las condiciones definidas en la
            <a routerLink="privacy-policy"> Política de Protección de Datos</a>
            que presenta Sitio Web.
        </p>
    </section>

    <section class="mt-5">
        <h4>8. DURACIÓN Y TERMINACIÓN</h4>
        <p>
            La prestación del servicio del presente Sitio Web y los demás
            servicios tienen en principio una duración indefinida. No obstante,
            la EMPRESA podrá dar por terminada o suspender cualquiera de los
            servicios del portal. Cuando sea ello posible, la EMPRESA anunciará
            la terminación o suspensión de la prestación del servicio
            determinado.
        </p>
    </section>

    <section class="mt-5">
        <h4>9. DECLARACIONES Y GARANTÍAS</h4>
        <p>
            En general, los contenidos y servicios ofrecidos en el Sitio Web
            tienen carácter meramente informativo. Por consiguiente, al
            ofrecerlos, la EMPRESA no otorga garantía ni declaración alguna en
            relación con los contenidos y servicios ofrecidos en el Sitio web,
            incluyendo, a título enunciativo, garantías de licitud, fiabilidad,
            utilidad, veracidad, exactitud, o comerciabilidad, salvo en la
            medida en que por ley no puedan excluirse tales declaraciones y
            garantías.
        </p>
    </section>

    <section class="mt-5">
        <h4>10. FUERZA MAYOR</h4>
        <p>
            La EMPRESA no será responsable en todo en caso de imposibilidad de
            prestar servicio, si ésta se debe a interrupciones prolongadas del
            suministro eléctrico, líneas de telecomunicaciones, conflictos
            sociales, huelgas, rebelión, explosiones, inundaciones, actos y
            omisiones del Gobierno, y en general todos los supuestos de fuerza
            mayor o de caso fortuito.
        </p>
    </section>

    <section class="mt-5">
        <h4>11. RESOLUCIÓN DE CONTROVERSIAS. LEY APLICABLE Y JURISDICCIÓN</h4>
        <p>
            Las presentes Condiciones Generales de Uso, así como el uso del
            Sitio Web, se regirán por la legislación española. Cualquier
            controversia será resuelta ante los tribunales de Córdoba.
        </p>
        <p>
            En el supuesto de que cualquier estipulación de las presentes
            Condiciones Generales de Uso resultara inexigible o nula en virtud
            de la legislación aplicable o como consecuencia de una resolución
            judicial o administrativa, dicha inexigibilidad o nulidad no hará
            que las presentes Condiciones Generales de Uso resulten inexigibles
            o nulas en su conjunto. En dichos casos, la EMPRESA procederá a la
            modificación o sustitución de dicha estipulación por otra que sea
            válida y exigible y que, en la medida de lo posible, consiga el
            objetivo y pretensión reflejados en la estipulación original.
        </p>
    </section>
</div>
