<!-- start site main header -->
<header class="main-header init-animation" [ngClass]="theme">
    <!-- start desktop logo -->
    <a
        href="#inicio"
        itemprop="url"
        class="logos matx-logo logo-initial init-animation"
    >
        <img class="logo-dark" [src]="logoPrimary" alt="logo Enooby" />
        <img class="logo-lite" [src]="logoSecondary" alt="logo Enooby Blanco" />
    </a>
    <!-- end desktop logo -->

    <!-- start desktop nav -->
    <nav class="nav-typo main-nav">
        <ul>
            <li>
                <a
                    href="#inicio"
                    itemprop="url"
                    data-target="#inicio"
                    class="menu-smooth-scroll"
                    >Inicio</a
                >
            </li>
            <li>
                <a
                    href="#sobre-nosotros"
                    itemprop="url"
                    data-target="#sobre-nosotros"
                    class="menu-smooth-scroll"
                    >Nosotros</a
                >
            </li>
            <li class="nav-item dropdown has-megamenu">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    data-toggle="dropdown"
                >
                    Google Workspace
                </a>
                <div class="dropdown-menu megamenu">
                    <div class="row mx-0">
                        <div class="col-md-9 border-right">
                            <a
                                href="https://workspace.google.es/intl/es/features/"
                                rel="noreferrer"
                            >
                                <h4>
                                    Aplicaciones Incluidas
                                    <i class="fas fa-arrow-right"></i>
                                </h4>
                            </a>

                            <div class="d-flex flex-wrap mt-3">
                                <div
                                    class="col-md-4"
                                    *ngFor="let gproduct of googleProducts"
                                >
                                    <a
                                        [href]="gproduct.url"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div class="col-3 text-right">
                                                <img
                                                    class="img img-fluid google-icon"
                                                    [src]="gproduct.image"
                                                    [alt]="gproduct.name"
                                                />
                                            </div>
                                            <div class="col-9">
                                                <div class="row">
                                                    <h6>{{ gproduct.name }}</h6>
                                                </div>
                                                <div class="row text-wrap">
                                                    <span>{{
                                                        gproduct.short_description
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <a
                                href="https://workspace.google.es/intl/es/security/"
                                rel="noreferrer"
                            >
                                <h4>
                                    Seguridad y Gestión
                                    <i class="fas fa-arrow-right"></i>
                                </h4>
                            </a>

                            <div class="d-flex flex-wrap mt-3">
                                <div
                                    class="col-md-12"
                                    *ngFor="
                                        let gproduct of googleProductsSecurity
                                    "
                                >
                                    <a
                                        [href]="gproduct.url"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div class="d-flex">
                                            <div class="col-3 text-right">
                                                <img
                                                    class="img img-fluid"
                                                    width="48px"
                                                    [src]="gproduct.image"
                                                    [alt]="gproduct.name"
                                                />
                                            </div>
                                            <div class="col-9">
                                                <div class="row">
                                                    <h6>{{ gproduct.name }}</h6>
                                                </div>
                                                <div class="row text-wrap">
                                                    <span>{{
                                                        gproduct.short_description
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- dropdown-mega-menu.// -->
            </li>
            <!-- <li>
                <a
                    href="#licencias"
                    itemprop="url"
                    data-target="#licencias"
                    class="menu-smooth-scroll"
                    >Licencias</a
                >
            </li> -->
            <li>
                <a
                    href="#servicios"
                    itemprop="url"
                    data-target="#servicios"
                    class="menu-smooth-scroll"
                    >Servicios</a
                >
            </li>
            <li>
                <a
                    href="/kit-digital"
                    itemprop="url"
                    data-target="/kit-digital"
                    >Kit Digital</a
                >
            </li>
            <li>
                <a
                    href="https://kit-consulting.enooby.com"
                    itemprop="url"
                    >Kit Consulting</a
                >
            </li>
            <li>
                <a
                    href="#contacto"
                    itemprop="url"
                    data-target="#contacto"
                    class="menu-smooth-scroll"
                    >Contacto</a
                >
            </li>
        </ul>
    </nav>
    <a href="/" class="back-btn"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 15L8 12M8 12L11 9M8 12L16 12M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg> VOLVER</a>
    <!-- end desktop nav -->

    <!-- start mobile logo -->
    <a href="#inicio" itemprop="url" class="logo matx-logo mobile-logo"> </a>
    <!-- end mobile logo -->

    <!-- toogle icons, which are responsible for display and hide menu in small layout -->
    <div class="mdl-layout__drawer-button mobile-menu-area">
        <i id="menu-back-btn" class="mobile-menu-ctrl fas fa-arrow-right"></i>
        <i id="menu-show-btn" class="mobile-menu-ctrl active fas fa-bars"></i>
    </div>
</header>

<!-- start mobile nav -->
<nav class="nav-typo mobile-nav">
    <ul>
        <li>
            <a
                href="#inicio"
                itemprop="url"
                data-target="#inicio"
                class="menu-smooth-scroll"
                >Inicio</a
            >
        </li>
        <li>
            <a
                href="#sobre-nosotros"
                itemprop="url"
                data-target="#sobre-nosotros"
                class="menu-smooth-scroll"
                >Nosotros</a
            >
        </li>
        <li>
            <a
                href="#google-workspace"
                itemprop="url"
                data-target="#google-workspace"
                class="menu-smooth-scroll"
                >Google Workspace</a
            >
        </li>
        <li>
            <a
                href="#servicios"
                itemprop="url"
                data-target="#servicios"
                class="menu-smooth-scroll"
                >Servicios</a
            >
        </li>
        <!-- <li>
            <a
                href="#licencias"
                itemprop="url"
                data-target="#licencias"
                class="menu-smooth-scroll"
                >Licencias</a
            >
        </li> -->
        <li>
            <a
                href="/kit-digital"
                itemprop="url"
                data-target="/kit-digital"
                >Kit Digital</a
            >
        </li>
        <li>
            <a
                href="https://kit-consulting.enooby.com"
                itemprop="url"
                >Kit Consulting</a
            >
        </li>
        <li>
            <a
                href="#contacto"
                itemprop="url"
                data-target="#contacto"
                class="menu-smooth-scroll"
                >Contacto</a
            >
        </li>
    </ul>
</nav>
<!-- end mobile nav -->
