<!-- start service section-->
<section id="servicios" class="section-main">
    <!-- start service tab section-->
    <section class="secondary-bg-color service-section service-with-thumb">
        <div class="section-common-space">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- start section title -->
                    <div class="text-center section-heading">
                        <h2 class="section-title title-font">SERVICIOS</h2>
                        <h3 class="section-subtitle">
                            Catálogo de servicios que ofrecemos
                        </h3>
                    </div>
                    <!-- end section title -->
                    <div class="service-tabs" data-action="count-up">
                        <div
                            class="clearfix mdl-tabs mdl-js-tabs mdl-js-ripple-effect"
                        >
                            <div class="col-md-12">
                                <div class="text-center">
                                    <!-- start tab menu item-->
                                    <div
                                        class="mdl-tabs__tab-bar matx-service-tabbar mdl-shadow--2dp"
                                    >
                                        <a
                                            href="#tab1"
                                            itemprop="url"
                                            class="mdl-tabs__tab is-active"
                                        >
                                            <i class="fas fa-briefcase"></i>
                                        </a>
                                        <a
                                            href="#tab2"
                                            itemprop="url"
                                            class="mdl-tabs__tab"
                                        >
                                            <i class="fas fa-cog"></i>
                                        </a>
                                        <a
                                            href="#tab3"
                                            itemprop="url"
                                            class="mdl-tabs__tab"
                                        >
                                            <i class="fas fa-graduation-cap"></i>
                                        </a>
                                    </div>
                                    <!-- end tab menu item-->
                                </div>
                                <div class="matx-tabpanel-wrap">
                                    <!-- start single tab-->
                                    <div
                                        class="mdl-tabs__panel animated animateSlow fadeIn matx-service-tabpanel is-active"
                                        id="tab1"
                                    >
                                        <div class="row tab-title-wrap">
                                            <div class="col-sm-6">
                                                <!-- service tab title -->
                                                <h3
                                                    class="card-title tab-title"
                                                >
                                                    CONSULTORÍA Y ASESORAMIENTO TECNOLÓGICO
                                                </h3>
                                                <p class="columnd-text">
                                                    Mediante sesiones de trabajo con nuestro equipo, ayudamos a identificar los procesos clave en tu organización que pueden ser digitalizados de manera ágil, maximizando la productividad con el uso de las últimas tecnologías, incluyendo la Inteligencia Artificial. Nuestro enfoque va más allá de la simple adopción de herramientas; nos centramos en integrar soluciones que optimicen el rendimiento, automatizando tareas repetitivas y mejorando la toma de decisiones.
                                                </p>

                                                <ul>
                                                    <li>
                                                        <b>Situación: </b>
                                                        Analizamos el estado actual de tu empresa en relación al uso de tecnologías, identificando áreas de mejora y oportunidades de automatización.
                                                    </li>
                                                    <li>
                                                        <b>Transformación: </b>
                                                        Diseñamos un plan estratégico que permita a tu organización adaptarse a las demandas actuales, impulsando una transformación digital eficaz.
                                                    </li>
                                                    <li>
                                                        <b>Colaboración: </b>
                                                        Evaluamos el uso de herramientas colaborativas y tecnológicas dentro de tu equipo, implementando soluciones que optimicen su integración y maximicen su utilidad.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-sm-6">
                                                <div
                                                    class="tab-content text-center"
                                                >
                                                    <!-- service featured image -->
                                                    <img
                                                        src="assets/img/services/servicios.jpg"
                                                        alt="servicios"
                                                        class="bordred-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end single tab-->

                                    <!-- start single tab-->
                                    <div
                                        class="mdl-tabs__panel animated animateSlow fadeIn matx-service-tabpanel"
                                        id="tab2"
                                    >
                                        <div class="row tab-title-wrap">
                                            <div class="col-sm-6">
                                                <!-- service tab title -->
                                                <h3
                                                    class="card-title tab-title"
                                                >
                                                    IMPLEMENTACIÓN Y MIGRACIÓN DE SOLUCIONES
                                                </h3>
                                                <p class="columnd-text">
                                                    En Enooby, somos expertos en la implementación y migración a Google Workspace, pero también adaptamos otras soluciones tecnológicas según las necesidades específicas de cada empresa. Nos aseguramos de que la transición sea fluida, minimizando interrupciones y garantizando que tu equipo aproveche al máximo las nuevas herramientas desde el primer día.
                                                </p>
                                                <p>Integramos automatizaciones con plataformas como <b>MAKE</b> para simplificar y optimizar procesos repetitivos, y aprovechamos la inteligencia artificial mediante herramientas como GPT, Perplexity, o Gemini para mejorar la eficiencia y toma de decisiones en tiempo real. Nuestro objetivo es crear un entorno digital que potencie la productividad y haga el día a día más ágil y eficaz.</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <div
                                                    class="tab-content text-center"
                                                >
                                                    <!-- service featured image -->
                                                    <img
                                                        src="assets/img/services/server.jpg"
                                                        alt="servidor"
                                                        class="bordred-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end single tab-->

                                    <!-- start single tab-->
                                    <div
                                        class="mdl-tabs__panel animated animateSlow fadeIn matx-service-tabpanel"
                                        id="tab3"
                                    >
                                        <div class="row tab-title-wrap">
                                            <div class="col-sm-6">
                                                <!-- service tab title -->
                                                <h3
                                                    class="card-title tab-title"
                                                >
                                                    FORMACIÓN Y PONENCIAS
                                                </h3>
                                                <p class="columnd-text">
                                                    Ofrecemos formación especializada en Google Workspace y en las últimas tecnologías, con un enfoque práctico y adaptado a las necesidades de cada organización. Ya sea que necesites mejorar la productividad con herramientas colaborativas o profundizar en el uso de la inteligencia artificial y las automatizaciones, nuestras sesiones están diseñadas para que tu equipo adquiera habilidades útiles desde el primer día.
                                                </p>

                                                <p>
                                                    Además, creamos formaciones y ponencias personalizadas, ajustadas a los desafíos y metas de tu empresa. Coméntanos tus necesidades específicas, y diseñaremos sesiones a medida para abordar exactamente lo que tu equipo requiere, ya sea en áreas como la automatización, la IA o la optimización de procesos digitales.
                                                </p>
                                            </div>
                                            <div class="col-sm-6">
                                                <div
                                                    class="tab-content text-center"
                                                >
                                                    <!-- service featured image -->
                                                    <img
                                                        src="assets/img/services/education.jpg"
                                                        alt="soporte"
                                                        class="bordred-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end single tab-->

                                </div>

                                <!-- end .matx-tabpanel-wrap-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end service tab section-->
</section>
<!-- end service section-->
