<section class="testimonial-section section-main">
    <div
        class="bg-image testimonial-bg"
        data-center="transform: translate3d(0px, -50%, 0px)"
        data-top-bottom="transform: translate3d(0px, -25%, 0px)"
        data-bottom-top="transform: translate3d(0px, -75%, 0px)"
    ></div>
    <div class="overlay overlay-four">
        <div class="section-common-space">
            <div
                class="testimonial-slider styled-paginantion colorsch-black"
                data-action="testimonialSlider"
            >
                <owl-carousel-o [options]="carouselOptions">
                    <ng-template carouselSlide
                        ><!-- start single testimonial -->
                        <div
                            class="text-center single-testimonial wow fadeIn"
                            data-wow-duration="1s"
                        >
                            <!-- start testimonial thumbnail -->
                            <div class="recomenders-thumb">
                                <img
                                    src="assets/img/clients/netbss.jpg"
                                    alt="netbss"
                                />
                            </div>
                            <!-- end testimonial thumbnail -->

                            <div class="recomenders-info">
                                <!-- testimonial text -->
                                <p>
                                    Gracias a Enooby hemos conseguido aumentar
                                    nuestra productividad de forma considerable.
                                    Valoramos mucho la rapidez y gracias a ellos
                                    hemos conseguido optener resultados en un
                                    tiempo record.
                                </p>

                                <!-- recommender name and designation -->
                                <div>
                                    <span class="recomenders-name"
                                        >Javier Carmona,</span
                                    >
                                    <span class="recomenders-role">
                                        Director General en netBSS</span
                                    >
                                </div>
                            </div>
                        </div>
                        <!-- end single testimonial --></ng-template
                    >
                    <ng-template carouselSlide
                        ><!-- start single testimonial -->
                        <div class="text-center single-testimonial">
                            <!-- start testimonial thumbnail -->
                            <div class="recomenders-thumb">
                                <img
                                    src="assets/img/clients/prodatcor.jpg"
                                    alt="prodatcor"
                                />
                            </div>
                            <!-- end testimonial thumbnail -->

                            <div class="recomenders-info">
                                <!-- testimonial text -->
                                <p>
                                    Nuestro día a día es mucho más eficiente. La
                                    facilidad de uso y estabilidad Google
                                    Workspace, hacen que todo sea sencillo y que
                                    nunca tengamos problemas. Ahora es muy fácil
                                    colaborar tanto en nuestro equipo como con
                                    nuestros clientes.
                                </p>

                                <!-- recommender name and designation -->
                                <div>
                                    <span class="recomenders-name"
                                        >Francisco Cano,</span
                                    >
                                    <span class="recomenders-role">
                                        Director en Prodatcor</span
                                    >
                                </div>
                            </div>
                        </div>
                        <!-- end single testimonial --></ng-template
                    >
                    <ng-template carouselSlide>
                        <!-- start single testimonial -->
                        <div class="text-center single-testimonial">
                            <!-- start testimonial thumbnail -->
                            <div class="recomenders-thumb">
                                <img
                                    src="assets/img/clients/paco_saban.png"
                                    alt="paco saban"
                                />
                            </div>
                            <!-- end testimonial thumbnail -->

                            <div class="recomenders-info">
                                <!-- testimonial text -->
                                <p>
                                    Gracias a Google Workspace y Enooby, hemos
                                    conseguido minimizar el riesgo de tener
                                    nuestra información almacenada en cada uno
                                    de nuestros equipos. Ahora podemos trabajar
                                    desde cualquier sitio de forma más segura y
                                    eficiente.
                                </p>

                                <!-- recommender name and designation -->
                                <div>
                                    <span class="recomenders-name"
                                        >Paco Sabán,</span
                                    >
                                    <span class="recomenders-role">
                                        Propietario de Correduría de Seguros
                                        Paco Sabán</span
                                    >
                                </div>
                            </div>
                        </div>
                        <!-- end single testimonial -->
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- start testimonial slider section -->

<!-- start clients or logo slider -->
<section class="secondary-bg-color clients">
    <div class="section-common-space">
        <div class="container">
            <div class="client-slider" data-action="clientSlider">
                <owl-carousel-o [options]="carouselClients">
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/Cabildo_Horizontal.png"
                                alt="Cabildo Catedral"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/UCO.png"
                                alt="Universidad de Córdoba"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/junta_andalucia.png"
                                alt="Junta de Andalucía"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/imtur.png"
                                alt="Imtur"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/ayuntamiento_cordoba.png"
                                alt="Ayuntamiento de Córdoba"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img src="assets/img/logo/CEA.png" alt="CEA" /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/grupo_unamacor.png"
                                alt="Grupo Unamacor"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide>
                        <div class="single-clients">
                            <img src="assets/img/logo/BNI.png" alt="BNI" /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/grupo_la_rabida.png"
                                alt="Grupo de Universidades la Rabida"
                            /></div
                    ></ng-template>

                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/albany.png"
                                alt="Albany School"
                            /></div
                    ></ng-template>

                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/alzahira.png"
                                alt="Grupo Alzahira"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/Bodegas_Mezquita.png"
                                alt="Bodegas Mezquita"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/Biotech.png"
                                alt="Biotech"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/paco_saban.png"
                                alt="Paco Sabán"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/La_Carloteña.png"
                                alt="La Carloteña"
                            /></div
                    ></ng-template>
                    <ng-template carouselSlide
                        ><div class="single-clients">
                            <img
                                src="assets/img/logo/cordoba_ecuestre.png"
                                alt="Córdoba Ecuestre"
                            /></div
                    ></ng-template>
                </owl-carousel-o>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="client-slider" data-action="clientSlider">
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                    <div class=""></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end clients or logo slider -->
