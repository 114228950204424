<!-- start main wrapper   -->
<main class="main-wrapper">
    <app-slider></app-slider>

    <!-- start intro section with icon box -->
    <section class="dark-section overflow-hidden boderd-intro-imodules">
        <div class="section-common-space">
            <div class="container">
                <div class="row">
                    <!-- start bordered icon box -->
                    <div class="col-xxs-12 col-xs-6 col-sm-6 col-md-3">
                        <div
                            class="single-imodule imodule-centerd rbodard-imodule wow fadeInUp"
                            data-wow-duration="1s"
                        >
                            <div class="icon-box">
                                <i class="fas fa-briefcase"></i>
                            </div>
                            <div class="ic-module-content">
                                <h3 class="imodule-title">PROFESIONALIDAD</h3>
                                <p>
                                    "Tenemos un plan estratégico, se llama hacer
                                    las cosas bien."
                                </p>
                                <p><b>Herb Kelleher</b></p>
                            </div>
                        </div>
                    </div>
                    <!-- end bordered icon box -->

                    <!-- start bordered icon box -->
                    <div class="col-xxs-12 col-xs-6 col-sm-6 col-md-3">
                        <div
                            class="single-imodule imodule-centerd rbodard-imodule wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".1s"
                        >
                            <div class="icon-box">
                                <!-- <i class="zmdi zmdi-lamp"></i> -->
                                <i class="far fa-lightbulb"></i>
                            </div>
                            <div class="ic-module-content">
                                <h3 class="imodule-title">INNOVACIÓN</h3>
                                <p>
                                    "No es posible resolver los problemas de hoy
                                    con las soluciones de ayer."
                                </p>
                                <p><b>Roger Van Oech</b></p>
                            </div>
                        </div>
                    </div>
                    <!-- end bordered icon box -->

                    <!-- start bordered icon box -->
                    <div class="col-xxs-12 col-xs-6 col-sm-6 col-md-3">
                        <div
                            class="single-imodule imodule-centerd rbodard-imodule wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".2s"
                        >
                            <div class="icon-box">
                                <!-- <i class="zmdi zmdi-puzzle-piece"></i> -->
                                <i class="fas fa-puzzle-piece"></i>
                            </div>
                            <div class="ic-module-content">
                                <h3 class="imodule-title">ENFOQUE PRÁCTICO</h3>
                                <p>
                                    "La forma de empezar es dejar de hablar y
                                    empezar a hacerlo."
                                </p>
                                <p><b>Walt Disney</b></p>
                            </div>
                        </div>
                    </div>
                    <!-- end bordered icon box -->

                    <!-- start bordered icon box -->
                    <div class="col-xxs-12 col-xs-6 col-sm-6 col-md-3">
                        <div
                            class="single-imodule imodule-centerd rbodard-imodule wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".3s"
                        >
                            <div class="icon-box">
                                <!-- <i class="zmdi zmdi-accounts-alt"></i> -->
                                <i class="fas fa-users"></i>
                            </div>
                            <div class="ic-module-content">
                                <h3 class="imodule-title">COLABORACIÓN</h3>
                                <p>
                                    "Si quieres llegar rápido, camina solo. Si
                                    quieres llegar lejos, camina en grupo."
                                </p>
                                <p><b>Proverbio Africano</b></p>
                            </div>
                        </div>
                    </div>
                    <!-- end bordered icon box -->
                </div>
            </div>
        </div>
    </section>
    <!-- end intro section with icon box -->

    <app-about-us></app-about-us>
    <app-why-choose-us></app-why-choose-us>
    <app-google-workspace></app-google-workspace>
    <!-- <app-google-workspace-pricing></app-google-workspace-pricing> -->
    <app-google-workspace-video></app-google-workspace-video>
    <app-services></app-services>
    <app-client-slider></app-client-slider>
    <app-twitter-slider></app-twitter-slider>
    <app-contact></app-contact>
</main>
