<!-- start why choose us accordion section -->
<section
    id="porque-elegirnos"
    class="dark-section overflow-hidden accordion-section section-main"
>
    <div
        class="bg-image why-choose-us-accordion-bg"
        data-center="transform: translate3d(0px, -50%, 0px)"
        data-top-bottom="transform: translate3d(0px, -25%, 0px)"
        data-bottom-top="transform: translate3d(0px, -75%, 0px)"
    ></div>

    <div class="overlay overlay-six">
        <div class="section-common-space">
            <div class="container">
                <div class="row">
                    <!-- start section title -->

                    <div class="col-sm-12 text-center sePor section-heading">
                        <h2 class="section-title title-font">
                            ¿Por qué elegirnos?
                        </h2>
                        <h3 class="section-subtitle">
                            Nuestra actitud, aptitud y experiencia aseguran la
                            satisfacción de nuestros clientes.
                        </h3>
                    </div>

                    <!-- end section title -->
                </div>
                <div
                    class="row specialties-accordion-wrap justify-content-center"
                    data-action="accordionWithImage"
                >
                    <div class="col-sm-12 col-md-7 col-lg-5">
                        <ul class="wcu-collapse">
                            <li
                                class="single-acc-item collapse-open"
                                data-image-src="assets/img/why-choose-us/mission.jpg"
                            >
                                <div class="collapse-label">
                                    <!-- accordion title -->
                                    <div class="cllabelmain">MISIÓN</div>
                                    <div class="icwrap">
                                        <i class="zmdi zmdi-plus"></i>
                                    </div>
                                </div>
                                <div class="collapse-content">
                                    Nuestra misión es <b>impulsar la productividad de nuestros clientes</b> de manera rápida y eficiente, utilizando la tecnología como herramienta clave y aplicando soluciones innovadoras en cada etapa, garantizando una transformación digital fluida y adaptada a las necesidades de cada empresa.
                                </div>
                            </li>
                            <li
                                class="single-acc-item"
                                data-image-src="assets/img/why-choose-us/team.png"
                            >
                                <div class="collapse-label">
                                    <!-- accordion title -->
                                    <div class="cllabelmain">EQUIPO</div>
                                    <div class="icwrap">
                                        <i class="zmdi zmdi-plus"></i>
                                    </div>
                                </div>
                                <div class="collapse-content">
                                    Contamos con un equipo multidisciplinar formado por profesionales cualificados con más de 25 años de experiencia en el sector de las nuevas tecnologías. Como filosofía de empresa, nos implicamos en las necesidades del cliente, comprometiéndonos directamente con sus objetivos y expectativas.
                                </div>
                            </li>
                            <li
                                class="single-acc-item"
                                data-image-src="assets/img/why-choose-us/googlecloud-partner.jpg"
                            >
                                <div class="collapse-label">
                                    <!-- accordion title -->
                                    <div class="cllabelmain">
                                        GOOGLE CLOUD PARTNER
                                    </div>
                                    <div class="icwrap">
                                        <i class="zmdi zmdi-plus"></i>
                                    </div>
                                </div>
                                <div class="collapse-content">
                                    En Enooby somos uno de los <b>Google Cloud Partners oficiales en España</b>, pero lo que realmente nos distingue es nuestro enfoque. No nos limitamos a vender licencias; nos aseguramos de que nuestros clientes utilicen correctamente todas las herramientas y aprovechen al máximo su potencial, optimizando sus procesos y mejorando su productividad desde el primer día.
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-5">
                        <div class="wcu-thumb-wrap acc-thumb-area">
                            <!-- accordion image preview -->
                            <img
                                src="assets/img/why-choose-us/mission.jpg"
                                alt="Google Cloud Partner"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end why choose us accordion section -->
