<!-- start main footer -->
<footer class="main-footer">
    <section class="footer-top">
        <div class="section">
            <div class="section-common-space">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="text-center ft-content-start">
                            <a
                                href="#inicio"
                                itemprop="url"
                                data-target="#inicio"
                                class="logo menu-smooth-scroll footer-logo wow fadeIn"
                                data-wow-duration="1s"
                            >
                                <img
                                    class="logo-footer"
                                    [src]="companyLogo"
                                    alt="logo Enooby"
                                />
                            </a>
                            <ul
                                class="clearfix social footer-social hover-round"
                            >
                                <li>
                                    <a
                                        [href]="companyFacebookUrl"
                                        itemprop="url"
                                        rel="noreferrer"
                                        target="_blank"
                                        ><i class="fab fa-facebook-f"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        [href]="companyTwitterUrl"
                                        itemprop="url"
                                        rel="noreferrer"
                                        target="_blank"
                                        ><i class="fab fa-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        [href]="companyLinkedinUrl"
                                        itemprop="url"
                                        rel="noreferrer"
                                        target="_blank"
                                        ><i class="fab fa-linkedin-in"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="footer-bottom">
        <div class="text-center">
            <p>
                Enooby &copy; {{ currentYear }} -
                <a id="aviso-legal" routerLink="/legal/legal-notice"
                    >Aviso Legal</a
                >
                &nbsp;&nbsp;<a
                    href="https://anydesk.es/escritorio-remoto"
                    rel="noreferrer"
                    target="_blank"
                    ><i
                        class="fa fa-icon-life-ring icon-font-2x"
                        aria-hidden="true"
                    ></i
                ></a>
            </p>
        </div>
    </section>
</footer>
<!-- end main footer -->

<!-- back to top button -->
<button
    id="backToTop"
    class="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect btn-highlight back-to-top shade-on hide-bottom"
>
    <i class="fas fa-arrow-down"></i>
</button>
