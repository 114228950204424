import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { CanonicalService } from './_services/canonical.service';
import { GlobalConstants } from './common/global-constants';
import { CookieService } from 'ngx-cookie-service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'Google Workspace | Enooby - Distribuidor Autorizado';

    cookieAccepted: boolean = false;

    constructor(
        private metaTagService: Meta,
        private titleService: Title,
        private canonicalService: CanonicalService,
        private cookieService: CookieService,
        public route: Router,
        @Inject(DOCUMENT) private dom
    ) {}

    ngOnInit(): void {
        // Check if cookies has been accpeted
        this.checkCookies();

        const URL = this.dom.URL;

        this.titleService.setTitle(this.title);

        this.metaTagService.addTags([
            {
                name: 'keywords',
                content: GlobalConstants.socialKeywords,
            },
            { name: 'robots', content: 'index, follow' },
            { name: 'author', content: GlobalConstants.companyName },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
            { charset: 'UTF-8' },
            {
                property: 'og:title',
                content: this.title,
            },
            {
                property: 'og:type',
                content: 'website',
            },
            {
                property: 'og:url',
                content: URL,
            },
            {
                property: 'og:image',
                content: GlobalConstants.socialImage,
            },
            {
                property: 'og:description',
                content: GlobalConstants.socialDescription,
            },
            {
                property: 'twitter:card',
                content: 'summary',
            },
            {
                property: 'twitter:site',
                content: GlobalConstants.twitterUser,
            },
            {
                property: 'twitter:creator',
                content: GlobalConstants.twitterUser,
            },
            {
                property: 'twitter:image',
                content: GlobalConstants.socialImage,
            },
        ]);

        this.metaTagService.updateTag({
            name: 'description',
            content: GlobalConstants.socialDescription,
        });

        this.canonicalService.setCanonicalURL();

        // Scroll to TOP on route change
        this.route.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    checkCookies() {
        const cookieExists: boolean = this.cookieService.check('acceptCookies');
        if (cookieExists) {
            const acceptCookies: string = this.cookieService.get(
                'acceptCookies'
            );
            if (acceptCookies == 'true') {
                this.cookieAccepted = true;
            } else {
                this.cookieAccepted = false;
            }
        }
    }
}
