<!-- start twitter slider section -->
<section class="twitter-section twitter overflow-hidden section-main">
    <div
        class="bg-image twitter-bg"
        data-center="transform: translate3d(0px, -50%, 0px)"
        data-top-bottom="transform: translate3d(0px, -25%, 0px)"
        data-bottom-top="transform: translate3d(0px, -75%, 0px)"
    ></div>

    <div class="overlay overlay-six">
        <div class="section-common-space">
            <div class="text-center">
                <div
                    class="twitter-thumb wow fadeInDown"
                    data-wow-duration="1s"
                >
                    <i class="zmdi zmdi-twitter"></i>
                </div>
                <div
                    class="slider-item tweets styled-paginantion colorsch-black"
                    id="tweetSlider"
                ></div>
            </div>
        </div>
    </div>
</section>
<!-- end twitter slider section -->
