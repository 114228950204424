<div class="cookie_notice fixed-bottom col-12 col-md-4" *ngIf="!isAccepted">
    <div class="d-flex flex-wrap justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center cookie_notice__img">
            <i class="fas fa-cookie-bite fa-5x"></i>
        </div>
        <div class="col-md-8 col-12 cookie_notice_content">
            <div class="cookie_notice__header my-4">
                <h4>Aviso de Cookies</h4>

                <p class="text-white">
                    Utilizamos cookies propias y de terceros con fines técnicos,
                    necesarios para el correcto uso de la página web y la
                    navegación por la misma.
                    <a class="text-white" routerLink="/legal/cookie-notice"
                        >Clica AQUÍ</a
                    >
                    para más información.
                </p>

                <button class="btn btn-outline-light" (click)="acceptCookies()">
                    Acepto
                </button>
            </div>
        </div>
    </div>
</div>
