import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-notice-page',
  templateUrl: './cookie-notice-page.component.html',
  styleUrls: ['./cookie-notice-page.component.scss']
})
export class CookieNoticePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
