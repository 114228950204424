import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'app-google-workspace-pricing',
    templateUrl: './google-workspace-pricing.component.html',
    styleUrls: ['./google-workspace-pricing.component.scss'],
})
export class GoogleWorkspacePricingComponent implements OnInit {
    productPricing = GlobalConstants.googleProductPricing;
    constructor() {}

    ngOnInit(): void {}
}
