import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CookieNoticePageComponent } from './_pages/cookie-notice-page/cookie-notice-page.component';
import { HomeComponent } from './_pages/home/home.component';
import { LegalNoticeComponent } from './_pages/legal-notice/legal-notice.component';
import { LegalPagesComponent } from './_pages/legal-pages/legal-pages.component';
import { PrivacyPolicyComponent } from './_pages/privacy-policy/privacy-policy.component';
import { DigitalKitComponent } from './_pages/digital-kit/digital-kit.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'legal',
        component: LegalPagesComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'privacy-policy',
            },
            {
                path: 'legal-notice',
                component: LegalNoticeComponent,
            },
            {
                path: 'cookie-notice',
                component: CookieNoticePageComponent,
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,
            },
        ],
    },
    {
        path: 'kit-digital',
        component: DigitalKitComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
