import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit {
    logoPrimary: string = GlobalConstants.logoPrimary;
    logoSecondary: string = GlobalConstants.logoSecondary;

    googleProducts = GlobalConstants.googleProducts;
    googleProductsSecurity = GlobalConstants.googleProductsSecurity;

    @Input()
    theme: string = null;

    constructor() {}

    ngOnInit(): void {}
}
